SubscriptionService.$inject = ['$http']

export default function SubscriptionService($http) {
  function calcDuration(startDateUtc, endDateUtc) {
    var start = Date.parse(startDateUtc)
    var end = Date.parse(endDateUtc)

    return end - start
  }
  
  return {
    getSubscriptions: function(profileId) {
      return $http.get('/api-prema2/subscriptions?filter={"where":{"profileId":"' + profileId + '","isPublished":true},"include":"recurrencePattern","order":"order ASC"}')
    },
    postMembership: function(membership, contact) {
      return $http.get('/api-prema2/memberships/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
        var memberships = res.data

        var data = {
          membershipNo: memberships.count + 1,
          referenceNo: (Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
          startDateUtc: membership.startDateUtc,
          duration: calcDuration(membership.startDateUtc, membership.startDateUtc),
          isRecurring: true,
          grossValue: membership.grossValue,
          netValue: membership.netValue,
          isCompleted: true,
          createdAt: new Date(),
          updatedAt: new Date(),
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId'),
          recurrencePatternId: membership.recurrencePattern.id
        }

        var promise = new Promise((resolve, reject) => {
          if (contact.id) {
            data.contactId = contact.id
            resolve()
          } else {
            return $http.get('/api-prema2/contactTypes').then(res => {
              var contactTypes = res.data
      
              var data = {
                firstName: AppService.toTitleCase(contact.firstName),
                lastName: contact.lastName ? AppService.toTitleCase(contact.lastName) : undefined,
                displayName: AppService.toTitleCase(contact.firstName),
                profileId: sessionStorage.getItem('profileId'),
                contactTypeId: contactTypes[2].id
              }
      
              if (contact.telephone || contact.email) data.communications = new Array()
              
              if (contact.email) data.communications.push({
                method: 'Email',
                value: contact.email,
                order: data.communications.length + 1,
                isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
              })
      
              if (contact.telephone) data.communications.push({
                method: 'Telephone',
                value: contact.telephone,
                order: data.communications.length + 1,
                isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
              })
      
              return $http.post('/api-prema2/contacts', data).then(res => {
                var contact = res.data
                
                data.contactId = contact.id
                resolve()
              })
            })
          }
        })
        
        return promise.then(() => $http.post('/api-prema2/memberships', data).then(res => {
          var membership2 = res.data

          membership.membershipLineItems.forEach(membershipLineItem => {
            membershipLineItem.membershipId = membership2.id

            $http.post('/api-prema2/membershipLineItems', membershipLineItem)
          })

          return membership2
        }))
      })
    }
  }
}