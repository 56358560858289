OrderService.$inject = ['$http', 'AppService']

export default function OrderService($http, AppService) {
  return {
    getDeliveryOptions: function(profileId) {
      return $http.get('/api-prema2/deliveryOptions?filter[where][profileId]=' + profileId)
    },
    getOrders: function() {
      return $http.get('/api-prema2/orders?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[include]=orderStatus&filter[order]=createdAt%20DESC')
    },
    getOrder: function(referenceNo) {
      return $http.get('/api-prema2/orders/findOne?filter={"where":{"referenceNo":"' + referenceNo + '","profileId":"' + sessionStorage.getItem('profileId') + '"},"include":[{"relation":"contact","scope":{"include":["contactType","locations"]}},"orderStatus","orderLineItems",{"relation":"activities","scope":{"order":"createdAt DESC","include":[{"relation":"createdBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},{"relation":"updatedBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},"activityType"]}}]}')
    },
    postOrder: function(contact, order, isExistingBillingAddress, isPrimaryLocationForShipping, location, locationShipping) {
      var firstName, lastName, businessName, email, telephone
      if (Array.isArray(contact)) {
        firstName = contact[0]
        lastName = contact[1]
        businessName = contact[2]
        email = contact[3]
        telephone = contact[4]

        return $http.get('/api-prema2/contactTypes').then(res => {
          var contactTypes = res.data

          var data = {
            firstName: AppService.toTitleCase(firstName),
            lastName: lastName ? AppService.toTitleCase(lastName) : undefined,
            displayName: AppService.toTitleCase(firstName),
            profileId: sessionStorage.getItem('profileId'),
            contactTypeId: businessName ? contactTypes[3].id : contactTypes[2].id
          }

          if (email || telephone) data.communications = new Array()
          
          if (email) data.communications.push({
            method: 'Email',
            value: email,
            order: data.communications.length + 1
          })

          if (telephone) data.communications.push({
            method: 'Telephone',
            value: telephone,
            order: data.communications.length + 1
          })

          return $http.post('/api-prema2/contacts', data).then(res => {
            var contact = res.data

            if (businessName) {
              var data = {
                businessName: AppService.toTitleCase(businessName),
                profileId: sessionStorage.getItem('profileId')
              }

              $http.post('/api-prema2/accounts', data).then(res => {
                var account = res.data

                var data = {
                  contactId: contact.id,
                  accountId: account.id
                }

                $http.post('/api-prema2/contactToAccounts', data)
              })
            }

            var data = {
              address: {
                street1: AppService.toTitleCase(location.street1),
                street2: location.street2 ? AppService.toTitleCase(location.street2) : undefined,
                street3: location.street3 ? AppService.toTitleCase(location.street3) : undefined,
                city: AppService.toTitleCase(location.city),
                county: AppService.toTitleCase(location.county),
                postcode: location.postcode.toUpperCase()
              },
              isPrimary: true,
              countryId: location.countryId
            }

            $http.post('/api-prema2/locations', data).then(res => {
              var location = res.data

              var data = {
                locationId: location.id,
                contactId: contact.id
              }

              $http.post('/api-prema2/locationToContacts', data)
            })

            if (locationShipping) {
              var data = {
                address: {
                  street1: AppService.toTitleCase(locationShipping.street1),
                  street2: locationShipping.street2 ? AppService.toTitleCase(locationShipping.street2) : undefined,
                  street3: locationShipping.street3 ? AppService.toTitleCase(locationShipping.street3) : undefined,
                  city: AppService.toTitleCase(locationShipping.city),
                  county: AppService.toTitleCase(locationShipping.county),
                  postcode: locationShipping.postcode.toUpperCase()
                },
                countryId: locationShipping.countryId
              }
    
              $http.post('/api-prema2/locations', data).then(res => {
                var location = res.data
    
                var data = {
                  locationId: location.id,
                  contactId: contact.id
                }
    
                $http.post('/api-prema2/locationToContacts', data)
              })
            }

            return $http.get('/api-prema2/orders/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
              var orders = res.data

              var data = {
                orderNo: orders.count + 1,
                referenceNo: (Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
                grossValue: order.grossValue,
                deliveryValue: order.deliveryValue,
                netValue: order.netValue,
                isPrimaryLocationForShipping: isPrimaryLocationForShipping ? true : false,
                isCompleted: true,
                createdAt: new Date(),
                updatedAt: new Date(),
                profileId: sessionStorage.getItem('profileId'),
                createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
                updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
                contactId: contact.id
              }

              return $http.get('/api-prema2/countries').then(res => {
                var countries = res.data

                data.locations = new Array()
                
                data.locations.push({
                  address: {
                    street1: AppService.toTitleCase(location.street1),
                    street2: location.street2 ? AppService.toTitleCase(location.street2) : undefined,
                    street3: location.street3 ? AppService.toTitleCase(location.street3) : undefined,
                    city: AppService.toTitleCase(location.city),
                    county: AppService.toTitleCase(location.county),
                    postcode: location.postcode.toUpperCase()
                  },
                  country: countries.find(country => country.id === location.countryId).country,
                  isPrimary: true
                })

                if (locationShipping) data.locations.push({
                  address: {
                    street1: AppService.toTitleCase(locationShipping.street1),
                    street2: locationShipping.street2 ? AppService.toTitleCase(locationShipping.street2) : undefined,
                    street3: locationShipping.street3 ? AppService.toTitleCase(locationShipping.street3) : undefined,
                    city: AppService.toTitleCase(locationShipping.city),
                    county: AppService.toTitleCase(locationShipping.county),
                    postcode: locationShipping.postcode.toUpperCase()
                  },
                  country: countries.find(country => country.id === locationShipping.countryId).country
                })

                return $http.post('/api-prema2/orders', data).then(res => {
                  var order = res.data

                  this.basketItems.forEach(basketItem => {
                    $http.get('/api-prema2/products/' + basketItem.productId).then(res => {
                      var product = res.data

                      var data = {
                        product: {
                          productNo: product.productNo ? product.productNo : undefined,
                          product: product.product,
                          unitSalePrice: product.unitSalePrice,
                          id: product.id
                        },
                        count: basketItem.count,
                        totalValue: basketItem.totalValue,
                        orderId: order.id
                      }
      
                      $http.post('/api-prema2/orderLineItems', data)
                    })
                  })

                  return order
                })
              })
            })
          })
        })
      } else {
        if (!isExistingBillingAddress) {
          var data = {
            address: {
              street1: AppService.toTitleCase(location.street1),
              street2: location.street2 ? AppService.toTitleCase(location.street2) : undefined,
              street3: location.street3 ? AppService.toTitleCase(location.street3) : undefined,
              city: AppService.toTitleCase(location.city),
              county: AppService.toTitleCase(location.county),
              postcode: location.postcode.toUpperCase()
            },
            isPrimary: true,
            countryId: location.countryId
          }

          $http.post('/api-prema2/locations', data).then(res => {
            var location = res.data

            var data = {
              locationId: location.id,
              contactId: contact.id
            }

            $http.post('/api-prema2/locationToContacts', data)

            if (locationShipping) {
              var data = {
                address: {
                  street1: AppService.toTitleCase(locationShipping.street1),
                  street2: locationShipping.street2 ? AppService.toTitleCase(locationShipping.street2) : undefined,
                  street3: locationShipping.street3 ? AppService.toTitleCase(locationShipping.street3) : undefined,
                  city: AppService.toTitleCase(locationShipping.city),
                  county: AppService.toTitleCase(locationShipping.county),
                  postcode: locationShipping.postcode.toUpperCase()
                },
                countryId: locationShipping.countryId
              }
    
              $http.post('/api-prema2/locations', data).then(res => {
                var location = res.data
    
                var data = {
                  locationId: location.id,
                  contactId: contact.id
                }
    
                $http.post('/api-prema2/locationToContacts', data)
              })
            }
          })
        }

        return $http.get('/api-prema2/orders/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
          var orders = res.data

          var data = {
            orderNo: orders.count + 1,
            referenceNo: order.referenceNo,
            grossValue: order.grossValue,
            deliveryValue: order.deliveryValue,
            netValue: order.netValue,
            isPrimaryLocationForShipping: isPrimaryLocationForShipping ? true : false,
            isCompleted: true,
            createdAt: new Date(),
            updatedAt: new Date(),
            profileId: sessionStorage.getItem('profileId'),
            createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
            updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
            contactId: contact.id
          }

          return $http.get('/api-prema2/countries').then(res => {
            var countries = res.data

            data.locations = new Array()
            
            data.locations.push({
              address: {
                street1: AppService.toTitleCase(location.street1),
                street2: location.street2 ? AppService.toTitleCase(location.street2) : undefined,
                street3: location.street3 ? AppService.toTitleCase(location.street3) : undefined,
                city: AppService.toTitleCase(location.city),
                county: AppService.toTitleCase(location.county),
                postcode: location.postcode.toUpperCase()
              },
              country: countries.find(country => country.id === location.countryId).country,
              isPrimary: true
            })

            if (locationShipping) data.locations.push({
              address: {
                street1: AppService.toTitleCase(locationShipping.street1),
                street2: locationShipping.street2 ? AppService.toTitleCase(locationShipping.street2) : undefined,
                street3: locationShipping.street3 ? AppService.toTitleCase(locationShipping.street3) : undefined,
                city: AppService.toTitleCase(locationShipping.city),
                county: AppService.toTitleCase(locationShipping.county),
                postcode: locationShipping.postcode.toUpperCase()
              },
              country: countries.find(country => country.id === locationShipping.countryId).country
            })

            return $http.post('/api-prema2/orders', data).then(res => {
              var order = res.data

              this.basketItems.forEach(basketItem => {
                $http.get('/api-prema2/products/' + basketItem.productId).then(res => {
                  var product = res.data

                  var data = {
                    product: {
                      productNo: product.productNo ? product.productNo : undefined,
                      product: product.product,
                      unitSalePrice: product.unitSalePrice,
                      id: product.id
                    },
                    count: basketItem.count,
                    totalValue: basketItem.totalValue,
                    orderId: order.id
                  }
  
                  $http.post('/api-prema2/orderLineItems', data)
                })
              })

              return order
            })
          })
        })
      }
    },
    basketItems: sessionStorage.getItem('basketItems') !== null ? JSON.parse(sessionStorage.getItem('basketItems')) : new Array()
  }
}