import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './product.routes'
import ProductService from './product.service'
import ProductCard from './product-card.directive'
import ProductStoreCard from './product-store-card.directive'
import ProductsController from './products.controller'
import ProductStoreController from './product-store.controller'
import ProductCategoryController from './product-category.controller'
import ProductController from './product.controller'
import './product.less'

export default angular.module('app.product', [uirouter, ProductCard, ProductStoreCard])
  .config(routing)
  .service('ProductService', ProductService)
  .controller('ProductsController', ProductsController)
  .controller('ProductCategoryController', ProductCategoryController)
  .controller('ProductStoreController', ProductStoreController)
  .controller('ProductController', ProductController)
  .name