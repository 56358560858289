import angular from 'angular'

function reviewCard() {
  return {
    restrict: 'E',
    replace: true,
    template: require('./_card-review.html')
  }
}

export default angular.module('directives.reviewCard', [])
  .directive('reviewCard', reviewCard)
  .name