import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './article.routes'
import ArticleService from './article.service'
import ArticleCard from './article-card.directive'
import ArticlesController from './articles.controller'
import ArticleController from './article.controller'
import './article.less'

export default angular.module('app.article', [uirouter, ArticleCard])
  .config(routing)
  .service('ArticleService', ArticleService)
  .controller('ArticlesController', ArticlesController)
  .controller('ArticleController', ArticleController)
  .name