AppService.$inject = ['$http', 'Upload', '$q', '$uibModal', '$rootScope']

export default function AppService($http, Upload, $q, $uibModal, $rootScope) {
  // Define these variables here as they are used inside this service
  function toTitleCase(str) {
    str = str.toLowerCase().split(' ')
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(' ')
  }
  function calcDuration(startDateUtc, endDateUtc) {
    var start = Date.parse(startDateUtc)
    var end = Date.parse(endDateUtc)

    return end - start
  }

  return {
    getComponentByName: function(component) {
      return $http.get('/api-prema2/components/findOne?filter[where][component]=' + toTitleCase(component))
    },
    toTitleCase: function(str) {
      return toTitleCase(str)
    },
    toSlug: function(str) {
      return str.replace(/&amp;/g, '').replace(/ - /g, '').replace(/\s\s/g, ' ').replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()
    },
    getProfile: function(uri) {
      return $http.get('/api-prema2/profiles/findOne?filter={"where":{"web":"' + uri + '"},"include":["businessEntity","businessSector",{"relation":"locations","scope":{"where":{"isPublished":true},"include":{"relation":"country","scope":{"include":"currency"}}}},{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"settings"]}')
    },
    getProfileId: function(uri) {
      return $http.get('/api-prema2/profiles/findOne?filter[where][web]=' + uri + '&filter[fields][id]=true')
    },
    getMedia: function(id) {
      return $http.get('/api-prema2/medias/findOne?filter[where][id]=' + id)
    },
    getMediaPlacements: function() {
      return $http.get('/api-prema2/mediaPlacements')
    },
    getCountries: function() {
      return $http.get('/api-prema2/countries')
    },
    getHomePageHeros: function(profileId) {
      return $http.get('/api-prema2/customFields?filter[where][profileId]=' + profileId).then(res => {
        var customFields = res.data
        if (customFields.length > 0) {
          return $http.get('/api-prema2/customFields/findOne?filter[where][profileId]=' + profileId + '&filter[where][field]=Home%20page%20heros&filter[include]=customItems&filter[order]=order%20ASC')
        }
      })
    },
    getHexPrimaryValue: function(color) {
      // Variables for red, green, blue values
      var r, g, b, hsp
      
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
        
        r = color[1]
        g = color[2]
        b = color[3]
      } 
      else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'))
  
        r = color >> 16
        g = color >> 8 & 255
        b = color & 255
      }
      
      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      )
  
      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 160) {
        return 'light'
      } 
      else {
        return 'dark'
      }
    },
    getContainers: function() {
      return $http.get('/api-prema2/containers')
    },
    postContainer: function() {
      var container = {
        name: sessionStorage.getItem('profileId')
      }

      return $http.post('/api-prema2/containers', container)
    },
    getFile: function(name) {
      return $http.get('/api-prema2/containers/' + sessionStorage.getItem('profileId') + '/download/' + name)
    },
    uploadFile: function(file) {
      return Upload.upload({
        url: '/api-prema2/containers/' + sessionStorage.getItem('profileId') + '/upload',
        headers: { 'Authorization' : localStorage.getItem('token') },
        file: file
      }).then(res => {
        return res
      }, function(res) {
        console.log('Error status: ' + res.status)
      }, function(event) {
        var uploadProgress = (Math.round((event.loaded * 100.0) / event.total)) - 1
        $rootScope.$broadcast('uploadProgress', uploadProgress)
      })
    },
    getMediaById: function(id) {
      return $http.get('/api-prema2/medias/' + id)
    },
    uploadMedia: function(file) {
      return Upload.upload({
        url: '/api-prema2/medias/upload',
        headers: { 'Authorization' : localStorage.getItem('token') },
        data: {
          file: file,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId')
        }
      }).then(res => {
        var defer = $q.defer()

        var file = res.data
        if (file.type.includes('video')) {
          $uibModal.open({
            controller: 'ModalVideoController',
            controllerAs: 'modalVideo',
            template: require('./video-modal-new.html'),
            resolve: {
              file: function() {
                return file
              }
            }
          }).result.then(attrs => {
            if (attrs) file.attrs = attrs
            file.updatedAt = new Date()
            file.updatedById = localStorage.getItem('userId')

            return $http.put('/api-prema2/medias/' + file.id, file, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
              defer.resolve(res)
            })
          })
        } else {
          defer.resolve(res)
        }

        return defer.promise
      }, function(res) {
        console.log('Error status: ' + res.status)
      }, function(event) {
        var uploadProgress = (Math.round((event.loaded * 100.0) / event.total)) - 1
        $rootScope.$broadcast('uploadProgress', uploadProgress)
      })
    },
    postLead: function(lead, contact) {
      return $http.get('/api-prema2/leadSources/findOne?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[where][source]=Website').then(res => {
        var leadSource = res.data
        
        var lead2 = {
          body: lead.body,
          isCompleted: true,
          createdAt: new Date(),
          updatedAt: new Date(),
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
          updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
          leadSourceId: leadSource.id
        }

        var promise = new Promise((resolve, reject) => {
          if (contact.id) {
            lead2.contactId = contact.id
            resolve()
          } else {
            return $http.get('/api-prema2/contactTypes').then(res => {
              var contactTypes = res.data
      
              var data = {
                firstName: toTitleCase(contact.firstName),
                lastName: contact.lastName ? toTitleCase(contact.lastName) : undefined,
                displayName: toTitleCase(contact.firstName),
                profileId: sessionStorage.getItem('profileId'),
                contactTypeId: contact.businessName ? contactTypes[3].id : contactTypes[2].id
              }
      
              if (contact.telephone || contact.email) data.communications = new Array()
              
              if (contact.email) data.communications.push({
                method: 'Email',
                value: contact.email,
                order: data.communications.length + 1,
                isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
              })
      
              if (contact.telephone) data.communications.push({
                method: 'Telephone',
                value: contact.telephone,
                order: data.communications.length + 1,
                isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
              })
      
              return $http.post('/api-prema2/contacts', data).then(res => {
                var contact2 = res.data

                lead2.contactId = contact2.id

                if (contact.businessName) {
                  var data = {
                    businessName: toTitleCase(contact.businessName),
                    profileId: sessionStorage.getItem('profileId')
                  }
      
                  $http.post('/api-prema2/accounts', data).then(res => {
                    var account = res.data
      
                    var data = {
                      contactId: contact2.id,
                      accountId: account.id
                    }
      
                    $http.post('/api-prema2/contactToAccounts', data)
                  })
                }

                resolve()
              })
            })
          }
        })

        return promise.then(() => $http.post('/api-prema2/leads', lead2))
      })
    },
    // getBookingTypes: function() {
    //   return $http.get('/api-prema2/bookingTypes?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '"},"include":"paymentType"}')
    // },
    postBooking: function(booking, contact) {
      return $http.get('/api-prema2/bookings/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
        var bookings = res.data

        return $http.get('/api-prema2/leadSources/findOne?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[where][source]=Website').then(res => {
          var leadSource = res.data

          var booking2 = {
            bookingNo: bookings.count + 1,
            referenceNo: booking.referenceNo,
            startDateUtc: booking.startDateUtc,
            duration: calcDuration(booking.startDateUtc, booking.startDateUtc),
            numberOfGuests: booking.numberOfGuests,
            notes: booking.notes,
            grossValue: booking.grossValue ? booking.grossValue : undefined,
            netValue: booking.netValue ? booking.netValue : undefined,
            isCompleted: true,
            createdAt: new Date(),
            updatedAt: new Date(),
            profileId: sessionStorage.getItem('profileId'),
            createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
            updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
            serviceId: booking.serviceId,
            leadSourceId: leadSource.id
          }

          var promise = new Promise((resolve, reject) => {
            if (contact.id) {
              booking2.contactId = contact.id
              resolve()
            } else {
              return $http.get('/api-prema2/contactTypes').then(res => {
                var contactTypes = res.data
        
                var data = {
                  firstName: toTitleCase(contact.firstName),
                  lastName: contact.lastName ? toTitleCase(contact.lastName) : undefined,
                  displayName: toTitleCase(contact.firstName),
                  profileId: sessionStorage.getItem('profileId'),
                  contactTypeId: contactTypes[2].id
                }
        
                if (contact.telephone || contact.email) data.communications = new Array()
                
                if (contact.email) data.communications.push({
                  method: 'Email',
                  value: contact.email,
                  order: data.communications.length + 1,
                  isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
                })
        
                if (contact.telephone) data.communications.push({
                  method: 'Telephone',
                  value: contact.telephone,
                  order: data.communications.length + 1,
                  isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
                })
        
                return $http.post('/api-prema2/contacts', data).then(res => {
                  var contact2 = res.data

                  booking2.contactId = contact2.id
                  resolve()
                })
              })
            }
          })

          return promise.then(() => $http.post('/api-prema2/bookings', booking2))
        })
      })
    },
    postReview: function(review, contact) {
      var review2 = {
        body: review.body,
        rating: review.rating,
        isCompleted: true,
        createdAt: new Date(),
        updatedAt: new Date(),
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
        updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined
      }

      var promise = new Promise((resolve, reject) => {
        if (contact.id) {
          review2.contactId = contact.id
          resolve()
        } else {
          return $http.get('/api-prema2/contactTypes').then(res => {
            var contactTypes = res.data
  
            var data = {
              firstName: toTitleCase(contact.firstName),
              lastName: contact.lastName ? toTitleCase(contact.lastName) : undefined,
              displayName: toTitleCase(contact.firstName),
              profileId: sessionStorage.getItem('profileId'),
              contactTypeId: contactTypes[2].id
            }
  
            if (contact.telephone || contact.email) data.communications = new Array()
            
            if (contact.email) data.communications.push({
              method: 'Email',
              value: contact.email,
              order: data.communications.length + 1,
              isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
            })
  
            if (contact.telephone) data.communications.push({
              method: 'Telephone',
              value: contact.telephone,
              order: data.communications.length + 1,
              isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
            })
  
            return $http.post('/api-prema2/contacts', data).then(res => {
              var contact2 = res.data

              review2.contactId = contact2.id
              resolve()
            })
          })
        }
      })

      return promise.then(() => $http.post('/api-prema2/reviews', review2))
    },
    postDonation: function(donation, contact) {
      var donation2 = {
        referenceNo: (Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
        netValue: donation.netValue,
        body: donation.body,
        isCompleted: true,
        createdAt: new Date(),
        updatedAt: new Date(),
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
        updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined
      }

      var promise = new Promise((resolve, reject) => {
        if (contact.id) {
          donation2.contactId = contact.id
          resolve()
        } else {
          return $http.get('/api-prema2/contactTypes').then(res => {
            var contactTypes = res.data
  
            var data = {
              firstName: toTitleCase(contact.firstName),
              lastName: contact.lastName ? toTitleCase(contact.lastName) : undefined,
              displayName: toTitleCase(contact.firstName),
              profileId: sessionStorage.getItem('profileId'),
              contactTypeId: contactTypes[2].id
            }
  
            if (contact.telephone || contact.email) data.communications = new Array()
            
            if (contact.email) data.communications.push({
              method: 'Email',
              value: contact.email,
              order: data.communications.length + 1,
              isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
            })
  
            if (contact.telephone) data.communications.push({
              method: 'Telephone',
              value: contact.telephone,
              order: data.communications.length + 1,
              isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
            })
  
            return $http.post('/api-prema2/contacts', data).then(res => {
              var contact2 = res.data

              donation2.contactId = contact2.id
              resolve()
            })
          })
        }
      })

      return promise.then(() => $http.post('/api-prema2/donations', donation2))
    },
    getCustomers: function() {
      return $http.get('/api-prema2/customers?filter[where][profileId]=' + sessionStorage.getItem('profileId'))
    },
    postCustomer: function(contact) {
      var name = contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.firstName
      var now = new Date()

      return $http.get('/api-prema2/customers/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
        var customers = res.data

        return $http.get('/api-prema2/leadSources/findOne?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[where][source]=Website').then(res => {
          var leadSource = res.data

          var data = {
            customerNo: (customers.count + 1).toString(),
            slug: name.replace(/&amp;/g, '').replace(/-/g, '').replace(/\s\s/g, ' ').replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
            startDate: new Date(now).setUTCHours(0, 0, 0, 0),
            isSysUser: true,
            createdAt: new Date(),
            updatedAt: new Date(),
            profileId: sessionStorage.getItem('profileId'),
            createdById: localStorage.getItem('userId'),
            updatedById: localStorage.getItem('userId'),
            contactId: contact.id,
            sysUserId: localStorage.getItem('userId'),
            leadSourceId: leadSource.id
          }
  
          return $http.post('/api-prema2/customers', data)
        })
      })
    },
    getQuestionnaire: function(questionnaireId) {
      return $http.get('/api-prema2/questionnaires/' + questionnaireId + '?filter={"include":{"relation":"questionnaireQuestions","scope":{"include":[{"relation":"questionnaireAnswers","scope":{"include":"questionnaireQuestion"}},"questionnaireQuestionType"]}}}')
    },
    postCustomerToQuestionnaire: function(customerId, questionnaireId) {
      var data = {
        customerId: customerId,
        questionnaireId: questionnaireId
      }
      
      return $http.post('/api-prema2/customerToQuestionnaires', data)
    },
    postQuestionnaireQuestionResponse: function(questionnaireQuestionResponse, contactId) {
      questionnaireQuestionResponse.answer = typeof questionnaireQuestionResponse.answer === 'object' ? questionnaireQuestionResponse.answer.answer : questionnaireQuestionResponse.answer
      questionnaireQuestionResponse.createdAt = new Date()
      questionnaireQuestionResponse.updatedAt = new Date()
      questionnaireQuestionResponse.createdById = localStorage.getItem('userId')
      questionnaireQuestionResponse.updatedById = localStorage.getItem('userId')
      questionnaireQuestionResponse.contactId = contactId

      return $http.post('/api-prema2/questionnaireQuestionResponses', questionnaireQuestionResponse)
    },
    getPaymentById: function(paymentId) {
      return $http.get('/api-prema2/payments/' + paymentId + '?filter[include]=paymentMethod&filter[include]=paymentStatus')
    },
    getComponentActivities: function(component, componentId) {
      return $http.get('/api-prema2/' + component + 's/' + componentId + '/activities?filter[include]=activityType')
    },
    postPayment: function(component, parent, payment) {
      return $http.get('/api-prema2/payments/count?where[contactId]=' + parent.contactId).then(res => {
        var payments = res.data

        var data = {
          paymentNo: payments.count + 1,
          referenceNo: parent.referenceNo,
          grossValue: parent.netValue,
          netValue: payment.netValue,
          createdAt: new Date(),
          updatedAt: new Date(),
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
          updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
          contactId: parent.contactId,
          paymentTypeId: payment.paymentTypeId,
          paymentMethodId: payment.paymentMethodId,
          paymentStatusId: payment.paymentStatusId
        }

        return $http.post('/api-prema2/payments', data).then(res => {
          var payment2 = res.data

          return $http.get('/api-prema2/activityTypes').then(res => {
            var activityTypes = res.data

            var activity = {
              objectId: payment2.id.toString(),
              createdAt: new Date(),
              updatedAt: new Date(),
              profileId: sessionStorage.getItem('profileId'),
              createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
              updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
              activityTypeId: activityTypes.find(activityType => activityType.type === 'Payment').id
            }

            return $http.post('/api-prema2/activities', activity).then(res => {
              var activity2 = res.data

              var activityToComponentVar = 'activityTo' + toTitleCase(component)
              var componentIdKey = component + 'Id'
              
              window[activityToComponentVar] = {
                activityId: activity2.id,
                [componentIdKey]: parent.id
              }

              return $http.post('/api-prema2/' + activityToComponentVar + 's', window[activityToComponentVar]).then(() => {
                // return this.getComponentActivities(component, parent.id)
                return payment2
              })
            })
          })
        })
      })
    },
    getProfilePaymentMethods: function(profileId) {
      return $http.get('/api-prema2/profilePaymentMethods?filter={"where":{"profileId":"' + profileId + '"},"include":"paymentMethod","order":"order ASC"}')
    },
    getReviews: function(profileId) {
      return $http.get('/api-prema2/reviews?filter={"where":{"profileId":"' + profileId + '","isPublished":true},"include":{"relation":"contact","scope":{"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}},"order":"createdAt DESC"}')
    }
  }
}