routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.locations', {
      url: '/locations?page',
      template: require('./locations.html'),
      controller: 'LocationsController',
      controllerAs: 'locations',
      resolve: {
        $title: function() {
          return 'Explore our spaces'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.location', {
      url: '/locations/:slug',
      template: require('./location.html'),
      controller: 'LocationController',
      controllerAs: 'location',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        location: ['LocationService', 'profile', '$stateParams', function(LocationService, profile, $stateParams) {
          return LocationService.getLocation(profile.data.id, $stateParams.slug)
        }],
        $title: ['location', function(location) {
          return location.data.location
        }]
      }
    })
}