import angular from 'angular'

paymentForm.$inject = ['AuthService', 'AppService', '$timeout', '$window']

function paymentForm(AuthService, AppService, $timeout, $window) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.resolve = Object.values(scope.$resolve)[0]
      scope.isAuthenticated = AuthService.isAuthenticated()
      if (scope.isAuthenticated) AuthService.getCurrentUser().then(res => scope.currentUser = res.data)
      scope.payment = new Object()
      AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => scope.profilePaymentMethods = res.data)

      scope.setProfilePaymentMethod = function(paymentMethodId) {
        scope.profilePaymentMethod = scope.profilePaymentMethods.find(profilePaymentMethod => profilePaymentMethod.paymentMethod.id === paymentMethodId)
      }
      scope.createPayment = function() {
        scope.submitted = true
        
        scope.payment.netValue = scope.resolve.netValue

        AppService.postPayment('booking', scope.resolve, scope.payment).then(res => {
          var payment = res

          var shell = scope.$$topModalIndex === 0 && scope.$$prevSibling ? scope.$$prevSibling.shell : scope.$$topModalIndex === 0 && scope.$$nextSibling ? scope.$$nextSibling.$$nextSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) scope.$close()
          if (scope.profilePaymentMethod.paymentMethod.method === 'Visa, American Express or MasterCard') {
            var hexPrimary = scope.$parent.profile.settings.hexPrimary.substring(1)
            $window.location.href = '//www.vivapayments.com/web2?ref=' + payment.orderCode + '&color=' + hexPrimary
          } else {
            shell.postedPayment = true
            $timeout(function() {
              shell.hideAlert('postedPayment')
            }, 5000)
          }
        })
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-payment.html')
  }
}

export default angular.module('directives.paymentForm', [])
  .directive('paymentForm', paymentForm)
  .name