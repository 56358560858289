OrderController.$inject = ['order', 'AppService']

export default function OrderController(order, AppService) {
  var vm = this

  function getPaymentsById(order) {
    var payments = order.activities.filter(activity => activity.activityType.type === 'Payment')
    payments.forEach(payment => AppService.getPaymentById(payment.objectId).then(res => {
      payment.object = res.data

      AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => {
        var profilePaymentMethods = res.data

        vm.profilePaymentMethod = profilePaymentMethods.find(profilePaymentMethod => profilePaymentMethod.paymentMethodId === payment.object.paymentMethodId)
      })
    }))
  }

  function init(order) {
    if (order.locations) {
      order.locations.primary = order.locations.find(location => location.isPrimary)
      order.locations.alt = order.locations.find(location => !location.isPrimary)
    }
    if (order.contact && order.contact.communications) order.contact.communications.email = order.contact.communications.find(communication => communication.method === 'Email') ? order.contact.communications.find(communication => communication.method === 'Email').value : undefined

    if (order.activities.length > 0) getPaymentsById(order)

    vm.order = order
  }

  init(order)
}