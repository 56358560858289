AboutController.$inject = ['$rootScope', 'CorporateService']

export default function AboutController($rootScope, CorporateService) {
  var vm = this

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      CorporateService.getTeam(profile.id).then(res => vm.team = res.data)
    }
  })

  vm.mapOptions = {}
  if (sessionStorage.getItem('theme')) {
    if (sessionStorage.getItem('theme') == 'dark') {
      vm.mapOptions = {
        styles: [
          {elementType: 'geometry', stylers: [{color: '#242424'}]},
          {elementType: 'labels.text.stroke', stylers: [{color: '#242424'}]},
          {elementType: 'labels.text.fill', stylers: [{color: '#747474'}]},
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d5d5d5'}]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d5d5d5'}]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{color: '#263c3f'}]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{color: '#6b9a76'}]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{color: '#383838'}]
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{color: '#212121'}]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{color: '#9c9c9c'}]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{color: '#747474'}]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{color: '#1f1f1f'}]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{color: '#f3f3f3'}]
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{color: '#2f2f2f'}]
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{color: '#d5d5d5'}]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{color: '#17263c'}]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{color: '#515c6d'}]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{color: '#17263c'}]
          }
        ]
      }
    }
  }
}