routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.subscriptions', {
      url: '/subscriptions',
      template: require('./subscriptions.html'),
      controller: 'SubscriptionsController',
      controllerAs: 'subscriptions',
      resolve: {
        $title: function() {
          return 'Become a member'
        }
      },
      params: {
        previous: null,
        slug: null
      }
    })
}