EventController.$inject = ['$rootScope', 'EventService', '$stateParams', 'AuthService', 'AppService', '$uibModal', '$timeout', '$state', '$scope', '$window']

export default function EventController($rootScope, EventService, $stateParams, AuthService, AppService, $uibModal, $timeout, $state, $scope, $window) {
  var vm = this

  function getPaymentsById(eventGuest) {
    var payments = eventGuest.activities.filter(activity => activity.activityType.type === 'Payment')
    var promise = new Promise((resolve, reject) => {
      var counter = 0
      payments.forEach(payment => AppService.getPaymentById(payment.objectId).then(res => {
        payment.object = res.data
        counter++
        if (counter === payments.length) resolve()
      }))
    })

    promise.then(() => {
      vm.isAtLeastOnePaymentVerified = payments.some(payment => payment.object.paymentStatusId)
      vm.isAtLeastOnePaymentPaid = payments.some(payment => payment.object.paymentStatusId && payment.object.paymentStatus.status === 'Paid')
      vm.isNotAllPaymentsPaid = payments.some(payment => !payment.object.paymentStatusId || payment.object.paymentStatus.status !== 'Paid')
      var paymentsNotPaidPaymentMethodIds = payments.filter(payment => !payment.object.paymentStatusId || payment.object.paymentStatus.status !== 'Paid').map(payment => payment.object.paymentMethodId)
      AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => {
        var profilePaymentMethods = res.data

        paymentsNotPaidPaymentMethodIds.forEach(paymentMethodId => vm.profilePaymentMethod = profilePaymentMethods.find(profilePaymentMethod => profilePaymentMethod.paymentMethodId === paymentMethodId))
      })
      vm.paymentsNotPaidTotalNetValue = payments.filter(payment => !payment.object.paymentStatusId || payment.object.paymentStatus.status !== 'Paid').map(payment => payment.object.netValue).reduce((a, b) => a + b, 0)
      $scope.$apply()
    })
  }

  function getEventGuests() {
    if (AuthService.isAuthenticated()) {
      AuthService.getCurrentUser().then(res => {
        var currentUser = res.data

        EventService.getEventGuestsByEventId(vm.event.id).then(res => {
          var eventGuests = res.data

          if (eventGuests) vm.eventGuests = eventGuests.filter(eventGuest => eventGuest.createdById === currentUser.id)
          if (vm.eventGuests.length > 0) {
            vm.eventGuest = vm.eventGuests.find(eventGuest => eventGuest.contactId === currentUser.contactId && eventGuest.isPrimary)

            vm.eventTicketTypeTotalUnitSalePrice = vm.eventGuests.map(eventGuest => eventGuest.eventTicketType.unitSalePrice).reduce((a, b) => a + b, 0)
            if (vm.eventGuest.activities.length > 0) getPaymentsById(vm.eventGuest)

            vm.isEventGuest = vm.eventGuests.find(eventGuest => eventGuest.contactId === currentUser.contactId) ? true : false
          }
        })
      })
    }
  }

  function getEvent(profile) {
    EventService.getEvent(profile.id, $stateParams.slug).then(res => {
      vm.event = res.data

      if (profile.settings.events.reveal) {
        vm.revealInHours = profile.settings.events.reveal / 1000 / 60 / 60
        vm.eventStartDateUtcMinusReveal = new Date(Date.parse(vm.event.startDateUtc) - profile.settings.events.reveal).toISOString()
      }

      vm.isEventEnded = new Date(vm.event.endDateUtc) < new Date() && !vm.event.isRecurring ? true : false

      vm.validEventTicketTypes = vm.event.eventTicketTypes.filter(eventTicketType => {
        if (eventTicketType.endDate) {
          if (new Date(eventTicketType.endDate) > new Date()) return eventTicketType
        } else {
          return eventTicketType
        }
      })
      vm.eventTicketTypesMinimumUnitSalePrice = Math.min(...vm.validEventTicketTypes.map(eventTicketType => eventTicketType.unitSalePrice))
      vm.eventTicketTypesMinimumUnitSalePriceType = vm.validEventTicketTypes.find(eventTicketType => eventTicketType.unitSalePrice === vm.eventTicketTypesMinimumUnitSalePrice).type

      getEventGuests()
    })
  }

  vm.now = new Date().toISOString()
  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      getEvent(profile)
    }
  })

  if ($window.location.search.includes('?guestlistConfirm')) {
    $uibModal.open({
      controller: 'ModalEventGuestController',
      controllerAs: 'modalEventGuest',
      template: require('./guestlist-modal-new.html'),
      resolve: {
        event: function() {
          return vm.event
        },
        eventGuests: function() {
          return vm.eventGuests
        }
      }
    }).result.then(email => {
      var body = $window.location.search.split('=')[1]
      EventService.getLeadsByBody(body).then(res => {
        var leads = res.data
        
        var lead = leads.find(lead => lead.contact.communications.find(communication => communication.value == email))
        EventService.qualifyLead(lead).then(() => {
          vm.confirmedGuestlist = true
          $timeout(function() {
            vm.hideAlert('confirmedGuestlist')
          }, 5000)
        })
      })
    })
  }

  vm.showNewGuestModal = function() {
    function modalInstance() {
      $uibModal.open({
        controller: 'ModalEventGuestController',
        controllerAs: 'modalEventGuest',
        template: require('./guest-modal-new.html'),
        resolve: {
          event: function() {
            return vm.event
          },
          eventGuests: function() {
            return vm.eventGuests
          }
        }
      }).result.then(() => $state.reload('shell.event'))
    }

    if (AuthService.isAuthenticated()) {
      modalInstance()
    } else {
      $uibModal.open({
        template: require('../auth/login-modal.html')
      }).result.then(() => modalInstance())
    }
  }
  vm.showUpdateGuestModal = function() {
    $uibModal.open({
      controller: 'ModalEventGuestController',
      controllerAs: 'modalEventGuest',
      template: require('./guest-modal-update.html'),
      resolve: {
        event: function() {
          return vm.event
        },
        eventGuests: function() {
          return vm.eventGuests
        }
      }
    }).result.then(() => {
      vm.updatedEventGuests = true
      $timeout(function() {
        vm.hideAlert('updatedEventGuests')
      }, 5000)
      getEventGuests()
    })
  }
  vm.showRemoveGuestModal = function() {
    $uibModal.open({
      controller: 'ModalEventGuestController',
      controllerAs: 'modalEventGuest',
      template: require('./guest-modal-remove.html'),
      resolve: {
        event: function() {
          return vm.event
        },
        eventGuests: function() {
          return vm.eventGuests
        }
      }
    }).result.then(() => {
      vm.updatedEventGuests = true
      $timeout(function() {
        vm.hideAlert('updatedEventGuests')
      }, 5000)
      getEventGuests()
    })
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}