import angular from 'angular'

donationForm.$inject = ['AuthService', 'AppService', '$uibModal', '$window']

function donationForm(AuthService, AppService, $uibModal, $window) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.isAuthenticated = AuthService.isAuthenticated()
      if (scope.isAuthenticated) AuthService.getCurrentUser().then(res => scope.currentUser = res.data)
      scope.donation = new Object(); scope.payment = new Object()

      scope.createDonation = function() {
        $uibModal.open({
          template: require('../app/donation-modal-new.html')
        }).result.then(res => {
          var contact = res

          AppService.postDonation(scope.donation, contact).then(res => {
            var donation = res.data

            scope.payment.netValue = donation.netValue
            scope.payment.paymentMethodId = '606338bacb9ba02c7a8f3a9e'

            AppService.postPayment('donation', donation, scope.payment).then(res => {
              var payment = res

              var hexPrimary = scope.$parent.$parent.profile.settings.hexPrimary.substring(1)
              $window.location.href = '//www.vivapayments.com/web2?ref=' + payment.orderCode + '&color=' + hexPrimary
            })
          })
        })
      }
    },
    template: require('./_form-donation.html')
  }
}

export default angular.module('directives.donationForm', [])
  .directive('donationForm', donationForm)
  .name