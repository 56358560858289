import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './service.routes'
import ServiceService from './service.service'
import ServiceCard from './service-card.directive'
import ServicesController from './services.controller'
import ServiceController from './service.controller'
import './service.less'

export default angular.module('app.service', [uirouter, ServiceCard])
  .config(routing)
  .service('ServiceService', ServiceService)
  .controller('ServicesController', ServicesController)
  .controller('ServiceController', ServiceController)
  .name