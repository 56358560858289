ServiceController.$inject = ['$rootScope', 'ServiceService', '$stateParams']

export default function ServiceController($rootScope, ServiceService, $stateParams) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      ServiceService.getService(profileId, $stateParams.slug).then(res => vm.service = res.data)
    }
  })
}