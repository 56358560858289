ProductStoreController.$inject = ['$rootScope', 'ProductService', '$stateParams', '$state']

export default function ProductStoreController($rootScope, ProductService, $stateParams, $state) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      ProductService.getProductStores(profileId).then(res => vm.productStores = res.data)
      ProductService.getProductStore(profileId, $stateParams.slug).then(res => {
        vm.productStore = res.data

        vm.productStoreProducts = vm.productStore.productCategories.map(productCategory => productCategory.products).reduce((a, c) => a.concat(c), [])
      })
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.numberOfPages = function() {
    return parseInt(vm.productStore.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
}