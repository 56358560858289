ProductCategoryController.$inject = ['$rootScope', 'ProductService', '$stateParams', '$state']

export default function ProductCategoryController($rootScope, ProductService, $stateParams, $state) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      ProductService.getProductStores(profileId).then(res => vm.productStores = res.data)
      ProductService.getProductCategory(profileId, $stateParams.slug).then(res => vm.productCategory = res.data)
    }
  })
  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.numberOfPages = function() {
    return parseInt(vm.productCategory.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
}