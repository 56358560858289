import angular from 'angular'

function locationCard() {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.headingClass = attrs.headingClass
      scope.showShortLede = attrs.showShortLede
    },
    template: require('./_card-location.html')
  }
}

export default angular.module('directives.locationCard', [])
  .directive('locationCard', locationCard)
  .name