routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.articles', {
      url: '/articles?page',
      template: require('./articles.html'),
      controller: 'ArticlesController',
      controllerAs: 'articles',
      resolve: {
        $title: function() {
          return 'Read the blog'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.article', {
      url: '/articles/:slug',
      template: require('./article.html'),
      controller: 'ArticleController',
      controllerAs: 'article',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        article: ['ArticleService', 'profile', '$stateParams', function(ArticleService, profile, $stateParams) {
          return ArticleService.getArticle(profile.data.id, $stateParams.slug)
        }],
        $title: ['article', function(article) {
          return article.data.post
        }]
      }
    })
}