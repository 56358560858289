StudyService.$inject = ['$http']

export default function StudyService($http) {
  return {
    getStudies: function(profileId) {
      return $http.get('/api-prema2/studies?filter={"where":{"profileId":"' + profileId + '","isPublished":true},"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"order":"order ASC"}')
    },
    getStudy: function(profileId, slug) {
      return $http.get('/api-prema2/studies/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":["durationUnit",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}')
    }
  }
}