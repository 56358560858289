LocationService.$inject = ['$http']

export default function LocationService($http) {
  return {
    getLocations: function(profileId) {
      return $http.get('/api-prema2/profiles/' + profileId + '/locations?filter={"where":{"isPublished":true,"isPrimary":{"neq":true}},"include":["country",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"order ASC"}')
    },
    getLocation: function(profileId, slug) {
      return $http.get('/api-prema2/locations/findOne?filter={"where":{"slug":"' + slug + '"},"include":["country",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}')
    }
  }
}