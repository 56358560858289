passwordForm.$inject = ['AuthService', '$location', '$state', '$timeout']

export default function passwordForm(AuthService, $location, $state, $timeout) {
  var directive = {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.changedPassword = false
      AuthService.getAccessTokenById(localStorage.getItem('token') ? localStorage.getItem('token') : $location.search().access_token).then(res => {
        scope.accessToken = res.data
      }, function() {
        $state.go('unauthorised')
      })

      scope.doChangePassword = function() {
        scope.submitted = true
        
        AuthService.changePassword(scope.password, scope.accessToken).then(() => {
          if (scope.$$topModalIndex === 0) scope.$dismiss()
          scope.changedPassword = true
          $timeout(function() {
            scope.hideAlert('changedPassword')
            $state.go('login')
          }, 5000)
        })
      }
      scope.dismissModal = function() {
        scope.$dismiss()
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-password.html')
  }

  return directive
}