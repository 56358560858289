routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.studies', {
      url: '/studies?page',
      template: require('./studies.html'),
      controller: 'StudiesController',
      controllerAs: 'studies',
      resolve: {
        $title: function() {
          return 'Read customer stories'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.study', {
      url: '/studies/:slug',
      template: require('./study.html'),
      controller: 'StudyController',
      controllerAs: 'study',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        study: ['StudyService', 'profile', '$stateParams', function(StudyService, profile, $stateParams) {
          return StudyService.getStudy(profile.data.id, $stateParams.slug)
        }],
        $title: ['study', function(study) {
          return study.data.study
        }]
      }
    })
}