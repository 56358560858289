routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.auth.me', {
      abstract: true,
      template: require('./me.html'),
      controller: 'MeController',
      controllerAs: 'me'
    })
    .state('shell.auth.me.home', {
      url: '/me',
      template: require('./home.html'),
      controller: 'MeHomeController',
      controllerAs: 'meHome',
      resolve: {
        $title: function() {
          return 'Me'
        }
      }
    })
    .state('shell.auth.me.profile', {
      url: '/me/edit',
      template: require('./profile.html'),
      controller: 'MeProfileController',
      controllerAs: 'meProfile',
      resolve: {
        $title: function() {
          return 'My profile'
        }
      }
    })
    .state('shell.auth.questionnaire', {
      url: '/me/questionnaire',
      template: require('./questionnaire.html'),
      controller: 'MeQuestionnaireController',
      controllerAs: 'meQuestionnaire',
      resolve: {
        $title: function() {
          return 'Questionnaire'
        }
      }
    })
}