ShellController.$inject = ['$transitions', '$rootScope', 'ProductService', 'AppService', 'OrderService', 'AuthService', '$state', '$uibModal', '$window', '$timeout']

export default function ShellController($transitions, $rootScope, ProductService, AppService, OrderService, AuthService, $state, $uibModal, $window, $timeout) {
  var vm = this

  $transitions.onSuccess({}, () => {
    if (vm.appSidebar) vm.appSidebar = false
  })
  vm.toggleAppSidebar = () => vm.appSidebar === true ? vm.appSidebar = false : vm.appSidebar = true
  vm.hideAppSidebar = () => vm.appSidebar = false
  vm.currentYear = new Date().getFullYear()
  if (window.innerWidth <= 767) {
    vm.display = 'small'
  } else if (window.innerWidth > 767 && window.innerWidth <= 1199) {
    vm.display = 'medium'
  } else {
    vm.display = 'large'
  }
  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      ProductService.getProductStores(profile.id).then(res => {
        vm.productStores = res.data
        if (vm.productStores.length === 1) vm.productStore = vm.productStores[0]
      })
    }
  })
  vm.basketItems = OrderService.basketItems
  vm.isAuthenticated = AuthService.isAuthenticated()
  if ($window.location.search.includes('?paymentSuccess')) {
    vm.successfulPayment = true
    $timeout(function() {
      vm.hideAlert('successfulPayment')
    }, 5000)
  } else if ($window.location.search.includes('?paymentFailure')) {
    vm.failurePayment = true
    $timeout(function() {
      vm.hideAlert('failurePayment')
    }, 5000)
  }

  angular.element(document).ready(function() {
    var logo = angular.element(document.getElementById('logo'))[0]
    var mainContent = angular.element(document.getElementById('main-content'))[0]
    var footerNav = angular.element(document.getElementById('footer-nav'))[0]

    if (logo) {
      logo.style.marginTop = (100 - logo.offsetHeight) / 2 + 'px'
      logo.style.marginBottom = (100 - logo.offsetHeight) / 2 + 'px'
    }
    
    mainContent.style.minHeight = window.getComputedStyle(mainContent, null).getPropertyValue('min-height').match(/\d+/) - footerNav.offsetHeight + 'px'
  })

  vm.searchProducts = function() {
    if (vm.search) $state.go('shell.products')
  }
  vm.showNewLeadModal = function() {
    if (vm.appSidebar) vm.appSidebar = false
    $uibModal.open({
      template: require('./lead-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.showNewBookingModal = function() {
    if (vm.appSidebar) vm.appSidebar = false
    $uibModal.open({
      template: require('./booking-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.showNewReviewModal = function() {
    if (vm.appSidebar) vm.appSidebar = false
    $uibModal.open({
      template: require('./review-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.showLoginModal = function() {
    if (vm.appSidebar) vm.appSidebar = false
    if ($window.location.href.includes('//about.prema.tech/')) {
      $window.location.href = '//prema.tech/'
    } else {
      $uibModal.open({
        template: require('../components/auth/login-modal.html'),
        controller: 'AuthController',
        controllerAs: 'auth'
      }).result.then(angular.noop, angular.noop)
    }
  }
  vm.setTheme = function(theme) {
    sessionStorage.setItem('theme', theme)
    $window.location.reload()
  }
  vm.doAction = action => {
    action.includes('()') ? eval('vm.' + action) : $state.go(action)
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}