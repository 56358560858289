routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.courses', {
      url: '/courses?page',
      template: require('./courses.html'),
      controller: 'CoursesController',
      controllerAs: 'courses',
      resolve: {
        $title: function() {
          return 'Explore courses'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.course', {
      url: '/courses/:slug',
      template: require('./course.html'),
      controller: 'CourseController',
      controllerAs: 'course',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        course: ['CourseService', 'profile', '$stateParams', function(CourseService, profile, $stateParams) {
          return CourseService.getCourse(profile.data.id, $stateParams.slug)
        }],
        $title: ['course', function(course) {
          return course.data.course
        }]
      }
    })
    .state('shell.lesson', {
      url: '/courses/:slug/lessons/:slug2',
      template: require('./lesson.html'),
      controller: 'LessonController',
      controllerAs: 'lesson',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        lesson: ['CourseService', 'profile', '$stateParams', function(CourseService, profile, $stateParams) {
          return CourseService.getLesson(profile.data.id, $stateParams.slug2)
        }],
        $title: ['lesson', function(lesson) {
          return lesson.data.lesson
        }]
      }
    })
}