ArticleController.$inject = ['$rootScope', 'ArticleService', '$stateParams']

export default function ArticleController($rootScope, ArticleService, $stateParams) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      ArticleService.getArticle(profileId, $stateParams.slug).then(res => vm.article = res.data)
    }
  })
}