import angular from 'angular'

elastic.$inject = ['$timeout']

function elastic($timeout) {
  return {
    restrict: 'C',
    link: function(scope, element) {
      scope.initialHeight = scope.initialHeight || element[0].style.height
      var resize = function() {
        element[0].style.height = scope.initialHeight
        element[0].style.height = '' + element[0].scrollHeight + 'px'
        element[0].style.overflow = 'hidden'
      }
      element.on('input change', resize)
      $timeout(resize, 1000)
    }
  }
}

export default angular.module('directives.elastic', [])
  .directive('elastic', elastic)
  .name