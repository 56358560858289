import angular from 'angular'

run.$inject = ['$location', 'AppService', '$rootScope', '$transitions', 'AuthService']

export default function run($location, AppService, $rootScope, $transitions, AuthService) {
  $rootScope.uri = $location.host() === 'preview.prema.tech' ? $location.host() + $location.url() : $location.host().replace('www.', '')
  // $rootScope.uri = 'thegreekitiki.com'

  function changeColourValue(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
  }

  function detectTheme() {
    var theme = 'light'

    // Local storage is used to override OS theme settings
    if (sessionStorage.getItem('theme')) {
      if (sessionStorage.getItem('theme') == 'dark') {
        var theme = 'dark'
      }
    } else if (!window.matchMedia) {
      // matchMedia method not supported
      return false
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // OS theme setting detected as dark
      var theme = 'dark'
    }

    // Dark theme preferred, set document with a `data-theme` attribute
    if (theme == 'dark') {
      sessionStorage.setItem('theme','dark')
      document.documentElement.setAttribute('data-theme', 'dark')
    }

    $rootScope.theme = theme
  }
  detectTheme()

  AppService.getProfile($rootScope.uri).then(res => {
    sessionStorage.setItem('profileId', res.data.id)
    $rootScope.profile = res.data
    $rootScope.appTitle = $rootScope.profile.businessName
    $rootScope.filePath = '/images/'
    $rootScope.filePath2 = '/api-prema2/containers/medias/download/'

    if ($rootScope.profile.locations.length > 0) {
      $rootScope.profile.locationPrimary = $rootScope.profile.locations.find(location => location.isPrimary)
      
      if ($rootScope.profile.locationPrimary.operatingHours) {
        var daysOfTheWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
        var operatingHours = $rootScope.profile.locationPrimary.operatingHours

        var select = (arr, obj) => arr.reduce((r, prop) => {
          !obj.hasOwnProperty(prop) && (r[prop] = obj[prop])
          return r
        }, {})

        var daysOfTheWeekMissing = select(daysOfTheWeek, operatingHours)
        Object.keys(daysOfTheWeekMissing).forEach(key => operatingHours[key] = undefined)

        var operatingHoursSortByDaysOfTheWeek = Object.entries(operatingHours).sort((a, b) => daysOfTheWeek.indexOf(a[0]) - daysOfTheWeek.indexOf(b[0]))
        var operatingHoursSorted = new Object()
        operatingHoursSortByDaysOfTheWeek.forEach(([key, value]) => operatingHoursSorted[key] = value)
        $rootScope.profile.locationPrimary.operatingHours = operatingHoursSorted
      }
    }

    if ($rootScope.profile.medias.filter(media => media.media.type.includes('video')).length > 0) {
      AppService.getMedia($rootScope.profile.medias.find(media => media.media.type.includes('video')).media.attrs.mediaId).then(res => $rootScope.profile.medias.find(media => media.media.type.includes('video')).media.attrs.media = res.data)
    }

    if ($rootScope.profile.settings.theme) {
      if ($rootScope.profile.settings.theme === 'dark') {
        sessionStorage.setItem('theme', 'dark')
        document.documentElement.setAttribute('data-theme', 'dark')
      } else {
        sessionStorage.setItem('theme', 'light')
        document.documentElement.setAttribute('data-theme', 'light')
      }
    }

    if ($rootScope.profile.settings.hexPrimary) {
      $rootScope.hexPrimaryValue = AppService.getHexPrimaryValue($rootScope.profile.settings.hexPrimary)
      $rootScope.hexPrimaryLighter = changeColourValue($rootScope.profile.settings.hexPrimary, 70)
      $rootScope.hexPrimaryDarker = changeColourValue($rootScope.profile.settings.hexPrimary, -30)
    }

    $rootScope.profile.settings.components.forEach(component => {
      $rootScope.profile.settings[component.name] = component

      if (component.customFields) component.customFields.forEach(customField => component[customField.name] = customField.value)
    })
  })

  $transitions.onBefore({to: ['shell.auth.**']}, $transition => {
    var expires = JSON.parse(localStorage.getItem('expires'))
    if (expires && (Date.now() > expires)) {
      window.stop()
      AuthService.logout()
      localStorage.clear()
      return $transition.router.stateService.target('shell.home')
    } else if (!expires || !localStorage.getItem('userId')) return $transition.router.stateService.target('shell.home')
  })
  
  $transitions.onSuccess({}, $transition => {
    document.body.scrollTop = document.documentElement.scrollTop = 0

    if (angular.isDefined($transition.injector().get('$title'))) $rootScope.pageTitle = $transition.injector().get('$title')
  })
}