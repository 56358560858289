import angular from 'angular'

function orderSummaryCard() {
  return {
    restrict: 'E',
    replace: true,
    scope: true,
    link: function(scope, elem, attrs) {
      scope.order = scope[attrs.parent].order
      scope.headingClass = attrs.headingClass
    },
    template: require('./_card-order-summary.html')
  }
}

export default angular.module('directives.orderSummaryCard', [])
  .directive('orderSummaryCard', orderSummaryCard)
  .name