AuthController.$inject = ['AuthService', '$uibModal', '$state']

export default function AuthController(AuthService, $uibModal, $state) {
  var vm = this

  vm.isAuthenticated = AuthService.isAuthenticated()
  if (vm.isAuthenticated) AuthService.getCurrentUser().then(res => vm.currentUser = res.data)
  vm.existingUser = false

  vm.showForgotPasswordModal = function() {
    $uibModal.open({
      template: require('./forgot-password-modal.html')
    }).result.then(angular.noop, angular.noop)
  }

  vm.doLogout = function() {
    if ($state.current.name.includes('shell.auth')) $state.go('shell.home')
    AuthService.logout().then(() => $state.reload())
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}