import angular from 'angular'

function bookingSummaryCard() {
  return {
    restrict: 'E',
    replace: true,
    scope: true,
    link: function(scope, elem, attrs) {
      scope.booking = scope[attrs.parent].booking
      scope.headingClass = attrs.headingClass
    },
    template: require('./_card-booking-summary.html')
  }
}

export default angular.module('directives.bookingSummaryCard', [])
  .directive('bookingSummaryCard', bookingSummaryCard)
  .name