TermsController.$inject = ['AuthService', 'AppService', 'MeService', '$scope', '$timeout', '$state']

export default function TermsController(AuthService, AppService, MeService, $scope, $timeout, $state) {
  var vm = this

  vm.doAcceptTerms = function() {
    AuthService.getCurrentUser().then(res => {
      var currentUser = res.data

      AppService.getComponentByName(currentUser.contact.contactType.type).then(res => {
        var component = res.data

        MeService.getProfileTasks(sessionStorage.getItem('profileId'), component.id).then(res => {
          var profileTasks = res.data

          var thisTask = profileTasks.find(profileTask => profileTask.task.action === 'shell.terms')

          MeService.postTaskResponse(thisTask.taskId, currentUser.contactId).then(() => $state.go('shell.auth.me.home'))
        })
      })
    })

    var shell = $scope.$parent.shell
    shell.acceptedTerms = true
    $timeout(function() {
      shell.hideAlert('acceptedTerms')
    }, 5000)
  }
}