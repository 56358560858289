import angular from 'angular'
import { loadScript } from '@paypal/paypal-js'

guestForm.$inject = ['AuthService', 'AppService', 'CorporateService', 'EventService', '$window', '$timeout']

function guestForm(AuthService, AppService, CorporateService, EventService, $window, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      function htmlDecode(input) {
        var e = document.createElement('div')
        e.innerHTML = input
        return e.childNodes[0].nodeValue
      }

      scope.event = scope.$resolve.event
      scope.event.eventTicketTypes = scope.event.eventTicketTypes.filter(eventTicketType => {
        eventTicketType.typeUnitSalePriceStr = htmlDecode(eventTicketType.type + ' (' + scope.$parent.profile.locationPrimary.country.currency.symbol + eventTicketType.unitSalePrice.toFixed(2) + ')')

        if (eventTicketType.endDate) {
          if (new Date(eventTicketType.endDate) > new Date()) return eventTicketType
        } else {
          return eventTicketType
        }
      })
      if (scope.event.location2) scope.event.location2.accommodationTypes = scope.event.location2.accommodationTypes.filter(accommodationType => {
        accommodationType.typeUnitSalePriceStr = htmlDecode(accommodationType.type + ' (' + scope.$parent.profile.locationPrimary.country.currency.symbol + accommodationType.unitSalePrice.toFixed(2) + ' per night)')

        if (accommodationType.endDate) {
          if (new Date(accommodationType.endDate) > new Date()) return accommodationType
        } else {
          return accommodationType
        }
      })
      scope.eventGuestStep = AuthService.isAuthenticated() ? 2 : 1
      scope.isAuthenticated = AuthService.isAuthenticated()
      if (scope.isAuthenticated) AuthService.getCurrentUser().then(res => {
        scope.currentUser = res.data

        scope.eventGuests = scope.$resolve.eventGuests
        if (scope.eventGuests) scope.eventGuestPrimary = scope.eventGuests.find(eventGuest => eventGuest.isPrimary)
        if (scope.eventGuestPrimary) {
          scope.additionalGuests = new Array()
          scope.additionalGuests.push({})
        }
      })
      scope.contact = new Object(); scope.eventGuest = new Object(); scope.payment = new Object()
      scope.eventTicketTypeTotalUnitSalePrice = 0; scope.accommodationTotalUnitSalePrice = 0
      AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => scope.profilePaymentMethods = res.data)
      CorporateService.getWorkers(sessionStorage.getItem('profileId')).then(res => {
        var workers = res.data

        scope.referrers = workers.filter(worker => worker.workerRoles.some(workerRole => workerRole.role === 'Referrer')).sort((a, b) => a.contact.firstName.localeCompare(b.contact.firstName))
      })
      
      scope.nextEventGuestStep = function() {
        scope.eventGuestStep++
      }
      scope.addAdditionalGuest = function() {
        if (!scope.additionalGuests) scope.additionalGuests = new Array()
        scope.additionalGuests.push({})
        if (scope.eventTicketType) {
          if (!scope.eventTicketType.multiplePurchaseDiscountPrice) scope.eventTicketType.multiplePurchaseDiscountPrice = 0
          scope.eventTicketTypeTotalUnitSalePrice = scope.eventTicketType.unitSalePrice + (scope.eventTicketType.unitSalePrice * scope.additionalGuests.length) - scope.eventTicketType.multiplePurchaseDiscountPrice
        }
      }
      scope.setEventTicketType = function(eventTicketTypeId) {
        scope.eventTicketType = scope.event.eventTicketTypes.find(eventTicketType => eventTicketType.id === eventTicketTypeId)
        if (!scope.eventTicketType.multiplePurchaseDiscountPrice) scope.eventTicketType.multiplePurchaseDiscountPrice = 0

        scope.eventTicketTypeTotalUnitSalePrice = scope.eventGuestPrimary && scope.additionalGuests ? (scope.eventTicketType.unitSalePrice * scope.additionalGuests.length) : !scope.eventGuestPrimary && scope.additionalGuests ? scope.eventTicketType.unitSalePrice + (scope.eventTicketType.unitSalePrice * scope.additionalGuests.length) - scope.eventTicketType.multiplePurchaseDiscountPrice : scope.eventTicketType.unitSalePrice
      }
      scope.setAccommodation = function(accommodationTypeId, accommodationAvailabilityId) {
        scope.accommodationType = scope.event.location2.accommodationTypes.find(accommodationType => accommodationType.id === accommodationTypeId)
        scope.accommodationAvailability = accommodationAvailabilityId ? scope.event.location2.accommodationAvailabilities.find(accommodationAvailability => accommodationAvailability.id === accommodationAvailabilityId) : { count: 1 }

        scope.accommodationTotalUnitSalePrice = scope.eventGuestPrimary && scope.additionalGuests ? (scope.accommodationType.unitSalePrice * scope.accommodationAvailability.count * scope.additionalGuests.length) : !scope.eventGuestPrimary && scope.additionalGuests ? (scope.accommodationType.unitSalePrice * scope.accommodationAvailability.count) + (scope.accommodationType.unitSalePrice * scope.accommodationAvailability.count * scope.additionalGuests.length) : scope.accommodationType.unitSalePrice * scope.accommodationAvailability.count
      }
      scope.setProfilePaymentMethod = function(paymentMethodId) {
        scope.profilePaymentMethod = scope.profilePaymentMethods.find(profilePaymentMethod => profilePaymentMethod.paymentMethod.id === paymentMethodId)
      }
      scope.createEventGuest = function() {
        scope.submitted = true

        if (!scope.eventGuestPrimary) {
          EventService.postEventGuest(scope.eventGuest, true, scope.event, scope.currentUser.contact).then(res => {
            var eventGuest = res.data

            eventGuest.netValue = scope.eventTicketTypeTotalUnitSalePrice + scope.accommodationTotalUnitSalePrice
            scope.payment.netValue = scope.eventTicketTypeTotalUnitSalePrice + scope.accommodationTotalUnitSalePrice

            AppService.postPayment('eventGuest', eventGuest, scope.payment).then(res => {
              var payment = res

              var promise = new Promise((resolve, reject) => {
                if (scope.additionalGuests) {
                  var counter = 0
                  scope.additionalGuests.forEach(additionalGuest => {
                    var eventGuest2 = new Object()
                    eventGuest2.referenceNo = eventGuest.referenceNo
                    eventGuest2.eventTicketTypeId = eventGuest.eventTicketTypeId
                    eventGuest2.accommodationTypeId = eventGuest.accommodationTypeId
                    eventGuest2.accommodationAvailabilityId = eventGuest.accommodationAvailabilityId
                    eventGuest2.referrerId = scope.eventGuest.referrerId ? scope.eventGuest.referrerId : undefined
                    EventService.postEventGuest(eventGuest2, undefined, scope.event, additionalGuest).then(() => {
                      counter++
                      if (counter === scope.additionalGuests.length) resolve()
                    })
                  })
                } else {
                  resolve()
                }
              })

              promise.then(() => {
                var shell = scope.$$topModalIndex === 0 && scope.$$prevSibling ? scope.$$prevSibling.shell : scope.$$topModalIndex === 0 && scope.$$nextSibling ? scope.$$nextSibling.$$nextSibling.shell : scope.$parent.shell
                if (scope.$$topModalIndex === 0) scope.$close()
                if (scope.profilePaymentMethod.paymentMethod.method === 'Visa, American Express or MasterCard') {
                  var hexPrimary = scope.$parent.profile.settings.hexPrimary.substring(1)
                  $window.location.href = '//www.vivapayments.com/web2?ref=' + payment.orderCode + '&color=' + hexPrimary
                } else {
                  shell.postedEventGuest = true
                  $timeout(function() {
                    shell.hideAlert('postedEventGuest')
                  }, 5000)
                }
              })
            })
          })
        } else {
          scope.eventGuest.referenceNo = scope.eventGuestPrimary.referenceNo
          scope.eventGuest.netValue = scope.eventTicketTypeTotalUnitSalePrice + scope.accommodationTotalUnitSalePrice
          scope.eventGuest.id = scope.eventGuestPrimary.id
          scope.eventGuest.contactId = scope.eventGuestPrimary.contactId
          scope.payment.netValue = scope.eventTicketTypeTotalUnitSalePrice + scope.accommodationTotalUnitSalePrice

          AppService.postPayment('eventGuest', scope.eventGuest, scope.payment).then(res => {
            var payment = res

            var promise = new Promise((resolve, reject) => {
              if (scope.additionalGuests) {
                var counter = 0
                scope.additionalGuests.forEach(additionalGuest => {
                  var eventGuest2 = new Object()
                  eventGuest2.referenceNo = scope.eventGuest.referenceNo
                  eventGuest2.eventTicketTypeId = scope.eventGuest.eventTicketTypeId
                  eventGuest2.accommodationTypeId = scope.eventGuest.accommodationTypeId
                  eventGuest2.accommodationAvailabilityId = scope.eventGuest.accommodationAvailabilityId
                  eventGuest2.referrerId = scope.eventGuest.referrerId ? scope.eventGuest.referrerId : undefined
                  EventService.postEventGuest(eventGuest2, undefined, scope.event, additionalGuest).then(() => {
                    counter++
                    if (counter === scope.additionalGuests.length) resolve()
                  })
                })
              } else {
                resolve()
              }
            })

            promise.then(() => {
              if (scope.$$topModalIndex === 0) scope.$close()
              if (scope.profilePaymentMethod.paymentMethod.method === 'Visa, American Express or MasterCard') {
                var hexPrimary = scope.$parent.profile.settings.hexPrimary.substring(1)
                $window.location.href = '//www.vivapayments.com/web2?ref=' + payment.orderCode + '&color=' + hexPrimary
              }
            })
          })
        }
      }
    },
    template: require('./_form-guest.html')
  }
}

export default angular.module('directives.guestForm', [])
  .directive('guestForm', guestForm)
  .name