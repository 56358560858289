CourseController.$inject = ['$rootScope', 'CourseService', '$stateParams']

export default function CourseController($rootScope, CourseService, $stateParams) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      CourseService.getCourse(profileId, $stateParams.slug).then(res => {
        vm.course = res.data

        vm.course.duration = !vm.course.duration ? vm.course.lessons.map(lesson => lesson.duration).reduce((a, b) => a + b, 0) : vm.course.duration
      })
    }
  })
}