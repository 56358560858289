forgotPasswordForm.$inject = ['AuthService']

export default function forgotPasswordForm(AuthService) {
  var directive = {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.doResetPassword = function() {
        scope.submitted = true

        AuthService.resetPasswordRequest(scope.email).then(() => scope.$dismiss())
      }
      scope.dismissModal = function() {
        scope.$dismiss()
      }
    },
    template: require('./_form-forgot-password.html')
  }

  return directive
}