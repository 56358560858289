import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './course.routes'
import CourseService from './course.service'
import CourseCard from './course-card.directive'
import CoursesController from './courses.controller'
import CourseController from './course.controller'
import LessonController from './lesson.controller'
import './course.less'

export default angular.module('app.course', [uirouter, CourseCard])
  .config(routing)
  .service('CourseService', CourseService)
  .controller('CoursesController', CoursesController)
  .controller('CourseController', CourseController)
  .controller('LessonController', LessonController)
  .name