PaymentMethodsController.$inject = ['$rootScope', 'CorporateService']

export default function PaymentMethodsController($rootScope, CorporateService) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      CorporateService.getProfilePaymentMethods(profileId).then(res => vm.profilePaymentMethods = res.data)
    }
  })
}