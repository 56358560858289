import angular from 'angular'

function employeeCard() {
  return {
    restrict: 'E',
    replace: true,
    template: require('./_card-employee.html')
  }
}

export default angular.module('directives.employeeCard', [])
  .directive('employeeCard', employeeCard)
  .name