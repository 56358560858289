import angular from 'angular'

function productStoreCard() {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.headingClass = attrs.headingClass
    },
    template: require('./_card-product-store.html')
  }
}

export default angular.module('directives.productStoreCard', [])
  .directive('productStoreCard', productStoreCard)
  .name