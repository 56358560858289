routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.products', {
      url: '/products?page',
      template: require('./products.html'),
      controller: 'ProductsController',
      controllerAs: 'products',
      resolve: {
        $title: function() {
          return 'Browse the shop'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.productStore', {
      url: '/products/stores/:slug?page',
      template: require('./product-store.html'),
      controller: 'ProductStoreController',
      controllerAs: 'productStore',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        productStore: ['ProductService', 'profile', '$stateParams', function(ProductService, profile, $stateParams) {
          return ProductService.getProductStore(profile.data.id, $stateParams.slug)
        }],
        $title: ['productStore', function(productStore) {
          return productStore.data.store
        }]
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.productCategory', {
      url: '/products/categories/:slug?page',
      template: require('./product-category.html'),
      controller: 'ProductCategoryController',
      controllerAs: 'productCategory',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        productCategory: ['ProductService', 'profile', '$stateParams', function(ProductService, profile, $stateParams) {
          return ProductService.getProductCategory(profile.data.id, $stateParams.slug)
        }],
        $title: ['productCategory', function(productCategory) {
          return productCategory.data.category
        }]
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.product', {
      url: '/products/:slug',
      template: require('./product.html'),
      controller: 'ProductController',
      controllerAs: 'product',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        product: ['ProductService', 'profile', '$stateParams', function(ProductService, profile, $stateParams) {
          return ProductService.getProduct(profile.data.id, $stateParams.slug)
        }],
        $title: ['product', function(product) {
          return product.data.product
        }]
      }
    })
}