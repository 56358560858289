LessonController.$inject = ['$rootScope', 'CourseService', '$stateParams', 'ArticleService']

export default function LessonController($rootScope, CourseService, $stateParams, ArticleService) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      CourseService.getLesson(profileId, $stateParams.slug2).then(res => {
        vm.lesson = res.data

        vm.lesson.resources.forEach(resource => {
          if (resource.type === 'Article') ArticleService.getArticle(profileId, resource.slug).then(res => resource.object = res.data)
        })

        CourseService.getCourse(profileId, $stateParams.slug).then(res => {
          var course = res.data
  
          var lessonIdx = course.lessons.findIndex(lesson => lesson.slug === vm.lesson.slug)
          vm.nextLessonIdx = course.lessons.length > lessonIdx + 1 ? lessonIdx + 1 : undefined
          vm.slug = vm.nextLessonIdx ? course.lessons[vm.nextLessonIdx].slug : undefined
        })
      })
    }
  })
}