import angular from 'angular'

leadForm.$inject = ['AuthService', 'AppService', '$timeout']

function leadForm(AuthService, AppService, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.isAuthenticated = AuthService.isAuthenticated()
      if (scope.isAuthenticated) AuthService.getCurrentUser().then(res => scope.currentUser = res.data)
      scope.contact = new Object(); scope.lead = new Object()

      scope.createLead = function() {
        scope.submitted = true
        
        AppService.postLead(scope.lead, scope.currentUser ? scope.currentUser.contact : scope.contact).then(() => {
          var shell = scope.$$topModalIndex === 0 ? scope.$$prevSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) {
            scope.$close()
          } else {
            scope.contact = new Object(); scope.lead = new Object()
          }
          shell.postedEnquiry = true
          $timeout(function() {
            shell.hideAlert('postedEnquiry')
          }, 5000)
        })
      }
    },
    template: require('./_form-lead.html')
  }
}

export default angular.module('directives.leadForm', [])
  .directive('leadForm', leadForm)
  .name