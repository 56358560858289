import angular from 'angular'

function courseCard() {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.headingClass = attrs.headingClass
    },
    template: require('./_card-course.html')
  }
}

export default angular.module('directives.courseCard', [])
  .directive('courseCard', courseCard)
  .name