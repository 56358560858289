import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './corporate.routes'
import CorporateService from './corporate.service'
import EmployeeCard from './employee-card.directive'
import AboutController from './about.controller'
import TeamController from './team.controller'
import TermsController from './terms.controller'
import PrivacyController from './privacy.controller'
import FaqsController from './faqs.controller'
import PaymentMethodsController from './payment-methods.controller'
import './corporate.less'

export default angular.module('app.corporate', [uirouter, EmployeeCard])
  .config(routing)
  .service('CorporateService', CorporateService)
  .controller('AboutController', AboutController)
  .controller('TeamController', TeamController)
  .controller('TermsController', TermsController)
  .controller('PrivacyController', PrivacyController)
  .controller('FaqsController', FaqsController)
  .controller('PaymentMethodsController', PaymentMethodsController)
  .name