import angular from 'angular'

donatorForm.$inject = ['AuthService', 'AppService']

function donatorForm(AuthService, AppService) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.isAuthenticated = AuthService.isAuthenticated()
      if (scope.isAuthenticated) AuthService.getCurrentUser().then(res => scope.currentUser = res.data)
      scope.contact = new Object()

      scope.createDonator = function() {
        scope.$close(scope.contact)
      }
    },
    template: require('./_form-donator.html')
  }
}

export default angular.module('directives.donatorForm', [])
  .directive('donatorForm', donatorForm)
  .name