import angular from 'angular'

reviewForm.$inject = ['AuthService', 'AppService', '$timeout']

function reviewForm(AuthService, AppService, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.isAuthenticated = AuthService.isAuthenticated()
      if (scope.isAuthenticated) AuthService.getCurrentUser().then(res => scope.currentUser = res.data)
      scope.contact = new Object(); scope.review = new Object()

      scope.setRating = function(index) {
        scope.review.rating = index
      }
      scope.createReview = function() {
        scope.submitted = true

        AppService.postReview(scope.review, scope.currentUser ? scope.currentUser.contact : scope.contact).then(() => {
          var shell = scope.$$topModalIndex === 0 ? scope.$$prevSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) {
            scope.$close()
          } else {
            scope.contact = new Object(); scope.review = new Object()
          }
          shell.postedReview = true
          $timeout(function() {
            shell.hideAlert('postedReview')
          }, 5000)
        })
      }
    },
    template: require('./_form-review.html')
  }
}

export default angular.module('directives.reviewForm', [])
  .directive('reviewForm', reviewForm)
  .name