import angular from 'angular'

function serviceCard() {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.headingClass = attrs.headingClass
    },
    template: require('./_card-service.html')
  }
}

export default angular.module('directives.serviceCard', [])
  .directive('serviceCard', serviceCard)
  .name