ProductService.$inject = ['$http']

export default function ProductService($http) {
  return {
    getProductStores: function(profileId) {
      return $http.get('/api-prema2/productStores?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"productCategories"],"order":"order ASC"}')
    },
    getProductStore: function(profileId, slug) {
      return $http.get('/api-prema2/productStores/findOne?filter={"where":{"slug":"' + slug + '","profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},{"relation":"productCategories","scope":{"include":[{"relation":"products","scope":{"where":{"isPublished":"true"},"include":["productVariations",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"product ASC"}}]}}]}')
    },
    getProductCategory: function(profileId, slug) {
      return $http.get('/api-prema2/productCategories/findOne?filter={"where":{"slug":"' + slug + '","profileId":"' + profileId + '"},"include":["productStore","productCategories",{"relation":"products","scope":{"where":{"isPublished":"true"},"include":["productVariations",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"product ASC"}}]}')
    },
    getProducts: function(profileId) {
      return $http.get('/api-prema2/products?filter={"where":{"profileId":"' + profileId + '","isPublished":true},"include":["productVariations",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"product ASC"}')
    },
    getProduct: function(profileId, slug) {
      return $http.get('/api-prema2/products/findOne?filter={"where":{"slug":"' + slug + '","profileId":"' + profileId + '"},"include":[{"relation":"productVariations","scope":{"where":{"isPublished":"true"},"order":"order ASC"}},{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"supplier"]}')
    },
    getProductById: function(id) {
      return $http.get('/api-prema2/products/' + id)
    }
  }
}