routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.events', {
      url: '/events',
      template: require('./events.html'),
      controller: 'EventsController',
      controllerAs: 'events',
      resolve: {
        $title: function() {
          return 'Discover what\'s on'
        }
      }
    })
    .state('shell.event', {
      url: '/events/:slug',
      template: require('./event.html'),
      controller: 'EventController',
      controllerAs: 'event',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        event: ['EventService', 'profile', '$stateParams', function(EventService, profile, $stateParams) {
          return EventService.getEvent(profile.data.id, $stateParams.slug)
        }],
        $title: ['event', function(event) {
          return event.data.event
        }]
      }
    })
    .state('shell.booking', {
      url: '/bookings/:referenceNo',
      template: require('./booking.html'),
      controller: 'BookingController',
      controllerAs: 'booking',
      resolve: {
        profile: ['AppService', '$rootScope', function(AppService, $rootScope) {
          return AppService.getProfileId($rootScope.uri)
        }],
        booking: ['EventService', 'profile', '$stateParams', function(EventService, profile, $stateParams) {
          return EventService.getBooking(profile.data.id, $stateParams.referenceNo).then(res => res.data)
        }],
        $title: ['booking', function(booking) {
          var name
          if (booking.contactId) {
            name = booking.contact.lastName ? booking.contact.firstName + ' ' + booking.contact.lastName : booking.contact.firstName
          } else {
            name = ''
          }
          return 'Ref. &#35;' + booking.referenceNo + ' ' + name
        }]
      }
    })
}