ModalMembershipController.$inject = ['membershipLineItems', '$scope', '$timeout', '$uibModalInstance']

export default function ModalMembershipController(membershipLineItems, $scope, $timeout, $uibModalInstance) {
  var vm = this

  vm.membershipLineItems = membershipLineItems
  vm.confirmDismiss = false

  $scope.$on('modal.closing', function(event, reason, closed) {
    if (!closed && reason !== 'cancel') {
      vm.confirmDismiss = true
      event.preventDefault()
    }
  })
  vm.doDismiss = function() {
    vm.hideAlert('confirmDismiss')
    $timeout(function() {
      $uibModalInstance.dismiss('cancel')
    }, 500)
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}