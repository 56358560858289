EventService.$inject = ['$http', 'AppService']

export default function EventService($http, AppService) {
  return {
    getEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '","isPublished":true},"include":["recurrencePattern",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}')
    },
    getUpcomingAndRecurringEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '","isPublished":true,"or":[{"and":[{"endDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":false}]},{"and":[{"recurrenceEndDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":true}]},{"and":[{"recurrenceEndDateUtc":{"neq":true}},{"isRecurring":true}]}]},"include":["recurrencePattern",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"startDateUtc ASC"}').then(res => {
        var events = res.data

        var eventsToRemove = events.filter(event => event.recurrenceEndDateUtc && new Date() >= new Date(event.recurrenceEndDateUtc))
        eventsToRemove.forEach(eventToRemove => events.splice(events.findIndex(event => event.recurrenceEndDateUtc === eventToRemove.recurrenceEndDateUtc), 1))
        
        res.data = events
        return res
      })
    },
    getUpcomingEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '","isPublished":true,"endDateUtc":{"gt":"' + new Date() + '"},"isRecurring":false},"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"order":"startDateUtc ASC"}')
    },
    getRecurringEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '","isPublished":true,"or":[{"and":[{"recurrenceEndDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":true}]},{"and":[{"recurrenceEndDateUtc":{"neq":true}},{"isRecurring":true}]}]},"include":["recurrencePattern",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"startDateUtc ASC"}').then(res => {
        var events = res.data

        var eventsToRemove = events.filter(event => event.recurrenceEndDateUtc && new Date() >= new Date(event.recurrenceEndDateUtc))
        eventsToRemove.forEach(eventToRemove => events.splice(events.findIndex(event => event.recurrenceEndDateUtc === eventToRemove.recurrenceEndDateUtc), 1))
        
        res.data = events
        return res
      })
    },
    getPastEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '","isPublished":true,"or":[{"and":[{"endDateUtc":{"lt":"' + new Date() + '"}},{"isRecurring":false}]},{"recurrenceEndDateUtc":{"lt":"' + new Date() + '"}}]},"include":["recurrencePattern",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"startDateUtc DESC"}')
    },
    getEvent: function(profileId, slug) {
      return $http.get('/api-prema2/events/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":["recurrencePattern",{"relation":"location2","scope":{"where":{"isPublished":true},"include":["country","accommodationTypes","accommodationAvailabilities"]}},{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},{"relation":"eventTicketTypes","scope":{"where":{"isPublished":"true"}}},{"relation":"worker","scope":{"include":"contact"}}]}')
    },
    postEventGuest: function(eventGuest, isPrimary, event, contact) {
      return $http.get('/api-prema2/eventGuests/count?where[eventId]=' + event.id).then(res => {
        var eventGuests = res.data

        eventGuest.ticketNo = eventGuests.count + 1
        eventGuest.referenceNo = eventGuest.referenceNo ? eventGuest.referenceNo : (Math.floor(Math.random() * 10000) + 10000).toString().substring(1)
        eventGuest.isPrimary = isPrimary
        eventGuest.createdAt = new Date()
        eventGuest.updatedAt = new Date()
        eventGuest.profileId = sessionStorage.getItem('profileId')
        eventGuest.createdById = localStorage.getItem('userId')
        eventGuest.updatedById = localStorage.getItem('userId')
        eventGuest.eventId = event.id

        var promise = new Promise((resolve, reject) => {
          if (contact.id) {
            eventGuest.contactId = contact.id
            resolve()
          } else {
            return $http.get('/api-prema2/contactTypes').then(res => {
              var contactTypes = res.data
      
              var data = {
                firstName: AppService.toTitleCase(contact.firstName),
                lastName: contact.lastName ? AppService.toTitleCase(contact.lastName) : undefined,
                displayName: AppService.toTitleCase(contact.firstName),
                profileId: sessionStorage.getItem('profileId'),
                contactTypeId: contactTypes[2].id
              }
      
              if (contact.telephone || contact.email) data.communications = new Array()
              
              if (contact.email) data.communications.push({
                method: 'Email',
                value: contact.email,
                order: data.communications.length + 1,
                isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
              })
      
              if (contact.telephone) data.communications.push({
                method: 'Telephone',
                value: contact.telephone,
                order: data.communications.length + 1,
                isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
              })
      
              return $http.post('/api-prema2/contacts', data).then(res => {
                var contact = res.data
                
                eventGuest.contactId = contact.id
                resolve()
              })
            })
          }
        })
        
        return promise.then(() => $http.post('/api-prema2/eventGuests', eventGuest))
      })
    },
    getEventGuestsByEventId: function(eventId) {
      return $http.get('/api-prema2/eventGuests?filter={"where":{"eventId":"' + eventId + '"},"include":["contact","eventTicketType","paymentStatus",{"relation":"activities","scope":{"order":"createdAt DESC","include":[{"relation":"createdBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},{"relation":"updatedBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},"activityType"]}}]}')
    },
    postEventResponse: function(eventGuest, eventResponseTypeId) {
      return $http.get('/api-prema2/eventResponses/count?where[contactId]=' + eventGuest.contactId).then(res => {
        var eventResponses = res.data

        var data = {
          eventResponseNo: eventResponses.count + 1,
          createdAt: new Date(),
          updatedAt: new Date(),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId'),
          eventId: eventGuest.eventId,
          eventGuestId: eventGuest.id,
          eventResponseTypeId: eventResponseTypeId
        }
        
        return $http.post('/api-prema2/eventResponses', data)
      })
    },
    getLeadsByBody: function(body) {
      return $http.get('/api-prema2/leads?filter={"where":{"body":"' + body + '"},"include":"contact"}')
    },
    qualifyLead: function(lead) {
      lead.updatedAt = new Date()
      delete(lead.updatedById)
      lead.leadStatusId = '590b41f02dc5a21c38dcda44'

      return $http.put('/api-prema2/leads/' + lead.id, lead)
    },
    getBooking: function(profileId, referenceNo) {
      return $http.get('/api-prema2/bookings/findOne?filter={"where":{"referenceNo":"' + referenceNo + '","profileId":"' + profileId + '"},"include":[{"relation":"contact","scope":{"include":"contactType"}},"service","bookingStatus","bookingLineItems",{"relation":"activities","scope":{"order":"createdAt DESC","include":[{"relation":"createdBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},{"relation":"updatedBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},"activityType"]}}]}')
    }
  }
}