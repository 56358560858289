routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.about', {
      url: '/about',
      template: require('./about.html'),
      controller: 'AboutController',
      controllerAs: 'about',
      resolve: {
        $title: function() {
          return 'Get to know us'
        }
      }
    })
    .state('shell.team', {
      url: '/team',
      template: require('./team.html'),
      controller: 'TeamController',
      controllerAs: 'team',
      resolve: {
        $title: function() {
          return 'Meet the team'
        }
      }
    })
    .state('shell.terms', {
      url: '/terms',
      template: require('./terms.html'),
      controller: 'TermsController',
      controllerAs: 'terms',
      resolve: {
        $title: function() {
          return 'Terms & conditions'
        }
      }
    })
    .state('shell.privacy', {
      url: '/privacy',
      template: require('./privacy.html'),
      controller: 'PrivacyController',
      controllerAs: 'privacy',
      resolve: {
        $title: function() {
          return 'Privacy policy'
        }
      }
    })
    .state('shell.faqs', {
      url: '/faqs',
      template: require('./faqs.html'),
      controller: 'FaqsController',
      controllerAs: 'faqs',
      resolve: {
        $title: function() {
          return 'FAQs'
        }
      }
    })
    .state('shell.paymentMethods', {
      url: '/payment-methods',
      template: require('./payment-methods.html'),
      controller: 'PaymentMethodsController',
      controllerAs: 'paymentMethods',
      resolve: {
        $title: function() {
          return 'Payment methods'
        }
      }
    })
}