import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './location.routes'
import LocationService from './location.service'
import LocationCard from './location-card.directive'
import LocationsController from './locations.controller'
import LocationController from './location.controller'
import './location.less'

export default angular.module('app.location', [uirouter, LocationCard])
  .config(routing)
  .service('LocationService', LocationService)
  .controller('LocationsController', LocationsController)
  .controller('LocationController', LocationController)
  .name