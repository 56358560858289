MeHomeController.$inject = ['$rootScope', 'EventService', 'ProductService', 'SubscriptionService', 'MeService', 'AuthService', 'AppService', '$state']

export default function MeHomeController($rootScope, EventService, ProductService, SubscriptionService, MeService, AuthService, AppService, $state) {
  var vm = this

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      EventService.getEvents(profile.id).then(res => vm.events = res.data)
      ProductService.getProducts(profile.id).then(res => vm.products = res.data)
      SubscriptionService.getSubscriptions(profile.id).then(res => vm.subscriptions = res.data)

      AuthService.getCurrentUser().then(res => {
        var currentUser = res.data

        MeService.getUpcomingAndRecurringEventsInclGuests().then(res => {
          var upcomingAndRecurringEvents = res.data
          if (profile.settings.events.reveal) {
            upcomingAndRecurringEvents.forEach(event => {
              event.revealInHours = profile.settings.events.reveal / 1000 / 60 / 60
              event.eventStartDateUtcMinusReveal = new Date(Date.parse(event.startDateUtc) - profile.settings.events.reveal).toISOString()
            })
          }
          vm.upcomingAndRecurringEvents = upcomingAndRecurringEvents.filter(event => event.eventGuests.some(eventGuest => eventGuest.contactId === currentUser.contactId))
        })
        MeService.getOrders(currentUser.contactId).then(res => vm.orders = res.data)
        MeService.getMemberships(currentUser.contactId).then(res => {
          vm.memberships = res.data

          function getPaymentById(membership) {
            var payment = membership.activities.reverse().find(activity => activity.activityType.type === 'Payment')
            if (payment) AppService.getPaymentById(payment.objectId).then(res => {
              membership.paymentMostRecent = res.data
        
              var now = new Date()
              var startDateUtc = new Date(membership.startDateUtc)
              var endDateUtc = membership.recurrencePattern.pattern === 'Every day' ? new Date(startDateUtc.setUTCDate(startDateUtc.getUTCDate() + 1)) : membership.recurrencePattern.pattern === 'Every week' ? new Date(startDateUtc.setUTCDate(startDateUtc.getUTCDate() + 7)) : membership.recurrencePattern.pattern === 'Every two weeks' ? new Date(startDateUtc.setDate(startDateUtc.getDate() + 14)) : membership.recurrencePattern.pattern === 'Every month' ? new Date(startDateUtc.setUTCMonth(startDateUtc.getUTCMonth() + 1)) : new Date(startDateUtc.setUTCYear(startDateUtc.getUTCYear() + 1))
              if (now > endDateUtc) membership.isExpired = true
            })
          }
          
          vm.memberships.forEach(membership => getPaymentById(membership) ? membership.activities.length > 0 : null)
        })

        if (currentUser.contact.contactType.type === 'Owner' || currentUser.contact.contactType.type === 'Employee' || currentUser.contact.contactType.type === 'Account') currentUser.contact.contactType.type = 'Customer'
        AppService.getComponentByName(currentUser.contact.contactType.type).then(res => {
          var component = res.data

          MeService.getProfileTasks(profile.id, component.id).then(res => {
            vm.profileTasks = res.data

            MeService.getTaskResponses(currentUser.contactId).then(res => {
              var taskResponses = res.data
  
              var intersections = vm.profileTasks.filter(profileTask => taskResponses.some(taskResponse => profileTask.taskId === taskResponse.taskId))
  
              vm.profileTasks.forEach(profileTask => {
                profileTask.isCompleted = intersections.find(intersection => intersection.taskId === profileTask.taskId) ? true : false
              })
            })
          })
        })
      })
    }
  })

  vm.doAction = action => {
    action.includes('()') ? eval('vm.' + action) : $state.go(action)
  }
}