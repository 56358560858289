routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.newSupplier', {
      url: '/work-with-us',
      template: require('./new-supplier.html'),
      controller: 'NewSupplierController',
      controllerAs: 'newSupplier',
      resolve: {
        $title: function() {
          return 'Work with us'
        }
      },
      params: {
        previous: null,
        slug: null
      }
    })
}