ModalPaymentController.$inject = ['$uibModalInstance', '$scope', '$timeout']

export default function ModalPaymentController($uibModalInstance, $scope, $timeout) {
  var vm = this

  vm.resolve = Object.values($scope.$resolve)[0]
  vm.doDismiss = function() {
    vm.hideAlert('confirmDismiss')
    $timeout(function() {
      $uibModalInstance.dismiss('cancel')
    }, 500)
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}