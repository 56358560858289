BookingController.$inject = ['booking', 'AppService', '$uibModal', '$state']

export default function BookingController(booking, AppService, $uibModal, $state) {
  var vm = this

  function getPaymentsById(booking) {
    var payments = booking.activities.filter(activity => activity.activityType.type === 'Payment')
    payments.forEach(payment => AppService.getPaymentById(payment.objectId).then(res => {
      payment.object = res.data

      AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => {
        var profilePaymentMethods = res.data

        vm.profilePaymentMethod = profilePaymentMethods.find(profilePaymentMethod => profilePaymentMethod.paymentMethodId === payment.object.paymentMethodId)
      })
    }))
  }

  function init(booking) {
    if (booking.duration) {
      booking.endDateUtc = ((new Date(booking.startDateUtc).getTime() / 1000) + booking.duration) * 1000
    }
    if (booking.locations) {
      booking.locations.primary = booking.locations.find(location => location.isPrimary)
      booking.locations.alt = booking.locations.find(location => !location.isPrimary)
    }
    if (booking.contact && booking.contact.communications) booking.contact.communications.email = booking.contact.communications.find(communication => communication.method === 'Email') ? booking.contact.communications.find(communication => communication.method === 'Email').value : undefined

    if (booking.activities.length > 0) getPaymentsById(booking)

    vm.booking = booking
  }

  init(booking)

  vm.showNewPaymentModal = function() {
    $uibModal.open({
      controller: 'ModalPaymentController',
      controllerAs: 'modalPayment',
      template: require('../../app/payment-modal-new.html'),
      resolve: {
        booking: function() {
          return vm.booking
        }
      }
    }).result.then(() => $state.reload('shell.auth.booking'))
  }
}