import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './me.routes'
import MeService from './me.service'
import MeController from './me.controller'
import MeHomeController from './home.controller'
import MeProfileController from './profile.controller'
import MeQuestionnaireController from './questionnaire.controller'
import './me.less'

export default angular.module('app.me', [uirouter])
  .config(routing)
  .service('MeService', MeService)
  .controller('MeController', MeController)
  .controller('MeHomeController', MeHomeController)
  .controller('MeProfileController', MeProfileController)
  .controller('MeQuestionnaireController', MeQuestionnaireController)
  .name