EventsController.$inject = ['$rootScope', 'EventService']

export default function EventsController($rootScope, EventService) {
  var vm = this

  vm.pastEventsOn = false

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      // For calendar view
      EventService.getEvents(profile.id).then(res => vm.events = res.data)

      // For list view
      EventService.getUpcomingEvents(profile.id).then(res => {
        vm.upcomingEvents = res.data

        vm.upcomingEvents.forEach(event => {
          if (profile.settings.events.reveal) {
            event.revealInHours = profile.settings.events.reveal / 1000 / 60 / 60
            event.eventStartDateUtcMinusReveal = new Date(Date.parse(event.startDateUtc) - profile.settings.events.reveal).toISOString()
          }
        })
      })

      EventService.getRecurringEvents(profile.id).then(res => {
        vm.recurringEvents = res.data

        vm.recurringEvents.forEach(event => {
          if (profile.settings.events.reveal) {
            event.revealInHours = profile.settings.events.reveal / 1000 / 60 / 60
            event.eventStartDateUtcMinusReveal = new Date(Date.parse(event.startDateUtc) - profile.settings.events.reveal).toISOString()
          }
        })

        vm.recurringEvents.map(recurringEvent => {
          recurringEvent.dayOfWeek = new Date(recurringEvent.startDateUtc).getDay()
          if (recurringEvent.dayOfWeek == 0) {
            recurringEvent.dayOfWeek = 7
          }
          return recurringEvent
        })
        var dayOfWeekToday = new Date().getDay()
        var daysOfWeek = [1,2,3,4,5,6,7]

        var arrOfDayOfWeek = vm.recurringEvents.map(recurringEvent => recurringEvent.dayOfWeek).sort((a, b) => a - b)
        var arrOfNotDayOfWeek = daysOfWeek.filter(item => !arrOfDayOfWeek.includes(item)).concat(arrOfDayOfWeek.filter(item => !daysOfWeek.includes(item)))
        var daysOfWeekSortByDayOfWeekToday = daysOfWeek.slice(dayOfWeekToday - 1).concat(daysOfWeek.slice(0,dayOfWeekToday - 1))
        var arrOfDayOfWeekSortByDayOfWeekToday = daysOfWeekSortByDayOfWeekToday.filter(item => !arrOfNotDayOfWeek.includes(item))

        vm.recurringEvents.sort((a, b) => arrOfDayOfWeekSortByDayOfWeekToday.indexOf(a.dayOfWeek) - arrOfDayOfWeekSortByDayOfWeekToday.indexOf(b.dayOfWeek))
      })
      
      EventService.getPastEvents(profile.id).then(res => {
        vm.pastEvents = res.data

        vm.pastEvents.forEach(event => {
          if (profile.settings.events.reveal) {
            event.revealInHours = profile.settings.events.reveal / 1000 / 60 / 60
            event.eventStartDateUtcMinusReveal = new Date(Date.parse(event.startDateUtc) - profile.settings.events.reveal).toISOString()
          }
        })
      })
      
      vm.settings = profile.settings.components.find(component => component.name === 'events')
    }
  })

  vm.viewDate = new Date()

  vm.setView = function() {
    vm.settings.view = vm.settings.view === 'list' ? 'calendar' : 'list'
  }
  vm.togglePastEvents = function() {
    vm.pastEventsOn = !vm.pastEventsOn
  }
}