import angular from 'angular'
import uirouter from 'angular-ui-router'
import uibootstrap from 'angular-ui-bootstrap'
import ngsantitize from 'angular-sanitize'
import ngAnimate from 'angular-animate'
import ngfileupload from 'ng-file-upload'
import checklistModel from 'checklist-model'
import 'angular-parallax-npm'
import 'angulargrid'
import 'angular-simple-logger'
import 'angular-google-maps'
import 'bootstrap-ui-datetime-picker'
import 'angular-moment'

import 'less/main.less'
import routing from './app.routes'
import run from './app.run'
import AppService from './app.service'
import ShellController from './shell.controller'
import ModalVideoController from './modal-video.controller'
import ModalPaymentController from './modal-payment.controller'
import PaginateFilter from './paginate.filter'
import HtmlFilter from './html.filter'
import ParseUrlFilter from './parse-url.filter'
import LeadFormDirective from './lead-form.directive'
import BookingFormDirective from './booking-form.directive'
import ReviewFormDirective from './review-form.directive'
import DonationFormDirective from './donation-form.directive'
import DonatorFormDirective from './donator-form.directive'
import PaymentFormDirective from './payment-form.directive'
import ReviewCard from './review-card.directive'
import ElasticDirective from './elastic.directive'
import auth from 'components/auth'
import home from 'components/home'
import study from 'components/study'
import article from 'components/article'
import service from 'components/service'
import product from 'components/product'
import event from 'components/event'
import corporate from 'components/corporate'
import order from 'components/order'
import me from 'components/me'
import supplier from 'components/supplier'
import subscription from 'components/subscription'
import course from 'components/course'
import location from 'components/location'

angular.module('app', [uirouter, uibootstrap, ngsantitize, ngAnimate, ngfileupload, checklistModel, LeadFormDirective, BookingFormDirective, ReviewFormDirective, DonationFormDirective, DonatorFormDirective, PaymentFormDirective, ReviewCard, ElasticDirective, 'angular-parallax', 'angularGrid', 'uiGmapgoogle-maps', 'ui.bootstrap.datetimepicker', 'angularMoment', auth, home, study, article, service, product, event, corporate, order, me, supplier, subscription, course, location])
  .config(routing)
  .run(run)
  .service('AppService', AppService)
  .controller('ShellController', ShellController)
  .controller('ModalVideoController', ModalVideoController)
  .controller('ModalPaymentController', ModalPaymentController)
  .filter('PaginateFilter', PaginateFilter)
  .filter('HtmlFilter', HtmlFilter)
  .filter('ParseUrlFilter', ParseUrlFilter)