export default function HtmlFilter() {
  var span = document.createElement('span')

  return function(input) {
    if (!input) {
      return input
    }

    // Because of the mix of line breaks and paragraphs, first we need to find all
    // paragraphs and replace all double newlines with HTML paragraph tags
    var images = input.replace(/<image>\n\n/g, '</p><div class="body-img"></div><p>')
    var paragraphs = images.replace(/\n\n/g, '</p><p>')

    // Now split the string by single newlines
    var breaks = paragraphs.split(/\n/)

    for (var j = 0; j < breaks.length; j++) {
      span.innerHTML = breaks[j]
    }

    // Add a leading HTML paragraph tag to the first index in the array
    breaks[0] = '<p>' + breaks[0]

    // Rejoin the split array with HTML line break tags
    return breaks.join('<br>')
  }
}