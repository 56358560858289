import angular from 'angular'

function productCard() {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.headingClass = attrs.headingClass

      if (scope.product.productVariations.length > 0) {
        var productVariationsUnitSalePrices = scope.product.productVariations.map(productVariation => productVariation.unitSalePrice)
        scope.productVariationsMinimumUnitSalePrice = Math.min(...productVariationsUnitSalePrices)
      }
    },
    template: require('./_card-product.html')
  }
}

export default angular.module('directives.productCard', [])
  .directive('productCard', productCard)
  .name