import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './order.routes'
import OrderService from './order.service'
import OrderSummaryCard from './order-summary-card.directive'
import BasketController from './basket.controller'
import CheckoutController from './checkout.controller'
import DeliveryInformationController from './delivery-information.controller'
import OrderController from './order.controller'
import './order.less'

export default angular.module('app.order', [uirouter, OrderSummaryCard])
  .config(routing)
  .service('OrderService', OrderService)
  .controller('BasketController', BasketController)
  .controller('CheckoutController', CheckoutController)
  .controller('DeliveryInformationController', DeliveryInformationController)
  .controller('OrderController', OrderController)
  .name