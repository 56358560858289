import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './supplier.routes'
import NewSupplierController from './new-supplier.controller'
import './supplier.less'

export default angular.module('app.supplier', [uirouter])
  .config(routing)
  .controller('NewSupplierController', NewSupplierController)
  .name