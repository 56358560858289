import { loadScript } from '@paypal/paypal-js'

CheckoutController.$inject = ['$rootScope', 'OrderService', 'AuthService', 'AppService', '$scope', '$window', '$state', '$timeout']

export default function CheckoutController($rootScope, OrderService, AuthService, AppService, $scope, $window, $state, $timeout) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      OrderService.getProfilePaymentMethods(profileId).then(res => vm.profilePaymentMethods = res.data)
    }
  })

  vm.checkoutStep = AuthService.isAuthenticated() ? 2 : 1
  vm.isAuthenticated = AuthService.isAuthenticated()
  if (vm.isAuthenticated) AuthService.getCurrentUser().then(res => {
    vm.currentUser = res.data

    if (vm.currentUser.contact.contactType.type === 'Customer') {
      if (vm.currentUser.contact.locations.length > 0) {
        vm.currentUser.contact.locationPrimary = vm.currentUser.contact.locations.find(location => location.isPrimary)
        vm.location = {
          street1: vm.currentUser.contact.locationPrimary.address.street1,
          street2: vm.currentUser.contact.locationPrimary.address.street2 ? vm.currentUser.contact.locationPrimary.address.street2 : undefined,
          street3: vm.currentUser.contact.locationPrimary.address.street3 ? vm.currentUser.contact.locationPrimary.address.street3 : undefined,
          city: vm.currentUser.contact.locationPrimary.address.city ? vm.currentUser.contact.locationPrimary.address.city : undefined,
          county: vm.currentUser.contact.locationPrimary.address.county ? vm.currentUser.contact.locationPrimary.address.county : undefined,
          postcode: vm.currentUser.contact.locationPrimary.address.postcode ? vm.currentUser.contact.locationPrimary.address.postcode : undefined,
          countryId: vm.currentUser.contact.locationPrimary.countryId
        }
      }
      vm.isPrimaryLocation = vm.currentUser.contact.locationPrimary ? true : false
    } else if (vm.currentUser.contact.contactType.type === 'Owner' || vm.currentUser.contact.contactType.type === 'Employee') {
      if (vm.currentUser.profile.locations.length > 0) {
        vm.currentUser.profile.locationPrimary = vm.currentUser.profile.locations.find(location => location.isPrimary)
      }
      vm.isPrimaryLocation = vm.currentUser.profile.locationPrimary ? true : false
    }
    
    vm.isExistingBillingAddress = AuthService.isAuthenticated() && vm.isPrimaryLocation ? true : false
  })
  vm.isPrimaryLocationForShipping = true
  var basketItemsTotal = parseFloat(sessionStorage.getItem('basketItemsTotal'))
  var deliveryValue = parseFloat(sessionStorage.getItem('standardDelivery'))
  vm.order = {
    grossValue: basketItemsTotal,
    deliveryValue: deliveryValue,
    netValue: basketItemsTotal + deliveryValue
  }
  vm.payment = new Object()
  AppService.getCountries().then(res => vm.countries = res.data)

  vm.setRememberMe = function() {
    vm.rememberMe = vm.password ? true : false
  }
  vm.nextCheckoutStep = function() {
    vm.checkoutStep++
  }
  vm.registerAndNextCheckoutStep = function() {
    AuthService.register(vm.firstName, vm.lastName, vm.businessName, vm.email, vm.password).then(() => {
      if (!localStorage.getItem('token')) AuthService.login(vm.email, vm.password).then(() => $state.reload())
    })
  }
  vm.setProfilePaymentMethod = function(paymentMethodId) {
    vm.profilePaymentMethod = vm.profilePaymentMethods.find(profilePaymentMethod => profilePaymentMethod.paymentMethod.id === paymentMethodId)
  }
  vm.createOrder = function() {
    vm.submitted = true

    OrderService.postOrder(vm.currentUser ? vm.currentUser.contact : [vm.firstName, vm.lastName, vm.businessName, vm.email, vm.telephone], vm.order, vm.isExistingBillingAddress, vm.isPrimaryLocationForShipping, vm.location, vm.locationShipping).then(res => {
      var order = res

      vm.payment.netValue = order.netValue
      
      AppService.postPayment('order', order, vm.payment).then(res => {
        var payment = res

        var shell = $scope.$parent.shell
        sessionStorage.removeItem('basketItems')
        sessionStorage.setItem('basketItemsTotal', 0)
        OrderService.basketItems = new Array()
        shell.basketItems = OrderService.basketItems
        if (vm.profilePaymentMethod.paymentMethod.method === 'Visa, American Express or MasterCard') {
          var hexPrimary = $rootScope.profile.settings.hexPrimary.substring(1)
          $window.location.href = '//www.vivapayments.com/web2?ref=' + payment.orderCode + '&color=' + hexPrimary
        } else {
          shell.postedOrder = true
          $state.go('shell.home')
          $timeout(function() {
            shell.hideAlert('postedOrder')
          }, 5000)
        }
      })
    })
  }
}