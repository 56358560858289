MeService.$inject = ['$http', 'AppService']

export default function MeService($http, AppService) {
  return {
    getAccount: function() {
      return $http.get('/api-prema2/contactToAccounts/findOne?filter[where][contactId]=' + contactId).then(res => {
        var contactToAccount = res.data

        return $http.get('/api-prema2/accounts/' + contactToAccount.accountId + '?filter={"include":["businessEntity",{"relation":"locations","include":"country"},{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}')
      })
    },
    getSupplier: function(contactId) {
      return $http.get('/api-prema2/contactToSuppliers/findOne?filter[where][contactId]=' + contactId).then(res => {
        var contactToSupplier = res.data

        return $http.get('/api-prema2/suppliers/' + contactToSupplier.supplierId + '?filter={"include":["businessEntity",{"relation":"locations","include":"country"},{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}')
      })
    },
    getProfileTasks: function(profileId, componentId) {
      return $http.get('/api-prema2/profileToTasks?filter[where][profileId]=' + profileId + '&filter[where][componentId]=' + componentId + '&filter[include]=task&filter[order]=order%20ASC')
    },
    getTaskResponses: function(contactId) {
      return $http.get('/api-prema2/taskResponses?filter[where][contactId]=' + contactId)
    },
    postTaskResponse: function(taskId, contactId) {
      return $http.get('/api-prema2/taskResponses/count?where[contactId]=' + contactId).then(res => {
        var taskResponses = res.data

        var data = {
          taskResponseNo: taskResponses.count + 1,
          createdAt: new Date(),
          updatedAt: new Date(),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId'),
          taskId: taskId,
          contactId: contactId
        }

        return $http.post('/api-prema2/taskResponses', data)
      })
    },
    getUpcomingAndRecurringEventsInclGuests: function() {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '","or":[{"and":[{"endDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":false}]},{"and":[{"recurrenceEndDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":true}]},{"and":[{"recurrenceEndDateUtc":{"neq":true}},{"isRecurring":true}]}]},"include":["eventGuests","recurrencePattern",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"startDateUtc ASC"}')
    },
    getOrders: function(contactId) {
      return $http.get('/api-prema2/orders?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[where][contactId]=' + contactId + '&filter[include]=orderStatus&filter[order]=createdAt%20DESC')
    },
    getMemberships: function(contactId) {
      return $http.get('/api-prema2/memberships?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '","contactId":"' + contactId + '"},"include":[{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}},"membershipType","membershipStatus","recurrencePattern",{"relation":"activities","scope":{"include":"activityType"}}],"order":"createdAt DESC"}')
    },
    putLocation: function(location, isPrimary, type, id) {
      location.isPrimary = isPrimary

      return $http.put('/api-prema2/locations', location).then(res => {
        var location2 = res.data

        if (type === 'Customer') {
          return $http.get('/api-prema2/locationToContacts/findOne?filter[where][locationId]=' + location2.id).then(() => {}, function() {
            var locationToContact = {
              locationId: location2.id,
              contactId: id
            }
  
            return $http.post('/api-prema2/locationToContacts', locationToContact)
          })
        } else if (type === 'Employee') {
          return $http.get('/api-prema2/locationToContacts/findOne?filter[where][locationId]=' + location2.id).then(() => {}, function() {
            var locationToContact = {
              locationId: location2.id,
              contactId: id
            }
  
            return $http.post('/api-prema2/locationToContacts', locationToContact)
          })
        } else if (type === 'Account') {
          return $http.get('/api-prema2/locationToAccounts/findOne?filter[where][locationId]=' + location2.id).then(() => {}, function() {
            var locationToAccount = {
              locationId: location2.id,
              accountId: id
            }
  
            return $http.post('/api-prema2/locationToAccounts', locationToAccount)
          })
        } else if (type === 'Supplier') {
          return $http.get('/api-prema2/locationToSuppliers/findOne?filter[where][locationId]=' + location2.id).then(() => {}, function() {
            var locationToSupplier = {
              locationId: location2.id,
              supplierId: id
            }
  
            return $http.post('/api-prema2/locationToSuppliers/', locationToSupplier)
          })
        }
      })
    },
    putSupplier: function(supplier) {
      return $http.put('/api-prema2/suppliers', supplier).then(() => {
        if (supplier.medias) {
          supplier.medias.forEach(media => {
            return $http.get('/api-prema2/supplierToMedias/findOne?filter[where][supplierId]=' + supplier.id).then(() => {}, function() {
              var supplierToMedia = {
                order: media.order,
                supplierId: supplier.id,
                mediaId: media.mediaId,
                mediaPlacementId: media.mediaPlacementId
              }
              
              return $http.post('/api-prema2/supplierToMedias', supplierToMedia)
            })
          })
        }
      })
    },
    putContact: function(contact) {
      return $http.put('/api-prema2/contacts', contact).then(() => {
        if (contact.medias) {
          contact.medias.forEach(media => {
            return $http.get('/api-prema2/contactToMedias/findOne?filter[where][contactId]=' + contact.id).then(() => {}, function() {
              var contactToMedia = {
                order: media.order,
                contactId: contact.id,
                mediaId: media.mediaId,
                mediaPlacementId: media.mediaPlacementId
              }
              
              return $http.post('/api-prema2/contactToMedias', contactToMedia)
            })
          })
        }
      })
    }
  }
}