StudyController.$inject = ['$rootScope', 'StudyService', '$stateParams']

export default function StudyController($rootScope, StudyService, $stateParams) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      StudyService.getStudy(profileId, $stateParams.slug).then(res => vm.study = res.data)
    }
  })
}