BasketController.$inject = ['$rootScope', 'OrderService', 'ProductService']

export default function BasketController($rootScope, OrderService, ProductService) {
  var vm = this

  function calcBasketItemsTotal() {
    vm.basketItemsTotal = vm.basketItems.map(basketItem => basketItem.totalValue).reduce((a, b) => a + b, 0)
    sessionStorage.setItem('basketItemsTotal', vm.basketItemsTotal)
  }
  function calcDeliveryEstimate(profileId) {
    OrderService.getDeliveryOptions(profileId).then(res => {
      var deliveryOptions = res.data
      vm.standardDelivery = deliveryOptions.length > 0 ? deliveryOptions.find(deliveryOption => deliveryOption.option == 'Standard') : new Object({ unitSalePrice: 0 })
      sessionStorage.setItem('standardDelivery', vm.standardDelivery.unitSalePrice)
    })
  }
  
  $rootScope.$watch('profile.id', function(profileId) {
    if (profileId !== undefined) {
      vm.basketItems = OrderService.basketItems

      vm.basketItems.forEach(function(basketItem) {
        ProductService.getProductById(basketItem.productId).then(res => {
          basketItem.product = res.data
          sessionStorage.setItem('basketItems', JSON.stringify(OrderService.basketItems))
        })
      })

      calcBasketItemsTotal()
      calcDeliveryEstimate(profileId)
    }
  })

  vm.delete = function(index) {
    vm.deleteFromBasket = true
    vm.index = index
  }
  vm.deleteBasketItem = function(index) {
    vm.hideAlert('deleteFromBasket')
    
    OrderService.basketItems.splice(index, 1)
    if (OrderService.basketItems.length > 0) {
      sessionStorage.setItem('basketItems', JSON.stringify(OrderService.basketItems))
    } else {
      ['basketItems', 'basketItemsTotal'].forEach(item => sessionStorage.removeItem(item))
    }

    $rootScope.$watch('profile.id', function(profileId) {
      if (profileId !== undefined) {
        calcBasketItemsTotal()
        calcDeliveryEstimate(profileId)
      }
    })
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}