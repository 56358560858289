MeQuestionnaireController.$inject = ['$rootScope', 'MeService', 'AppService', 'AuthService', '$scope', '$timeout', '$state']

export default function MeQuestionnaireController($rootScope, MeService, AppService, AuthService, $scope, $timeout, $state) {
  var vm = this

  vm.questionnaireQuestionResponses = new Array()
  vm.questionnaireQuestionStep = 1

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      AppService.getQuestionnaire(profile.settings.questionnaireId).then(res => {
        vm.questionnaire = res.data

        vm.questionnaire.questionnaireQuestions.forEach(questionnaireQuestion => {
          vm.questionnaireQuestionResponses.push({
            questionnaireId: vm.questionnaire.id,
            questionnaireQuestionId: questionnaireQuestion.id
          })
        })
      })
    }
  })

  vm.questionnaireQuestionNextStep = function() {
    vm.questionnaireQuestionStep++
  }

  vm.postQuestionnaire = function() {
    vm.submitted = true
    
    AuthService.getCurrentUser().then(res => {
      var currentUser = res.data

      AppService.getCustomers().then(res => {
        var customers = res.data

        var customer
        var getOrPostCustomer = new Promise((resolve, reject) => {
          customer = customers.find(customer => customer.contactId === currentUser.contactId)
          if (customer) {
            resolve()
          } else {
            AppService.postCustomer(currentUser.contact).then(res => {
              customer = res.data
              resolve()
            })
          }
        })

        getOrPostCustomer.then(() => {
          AppService.postCustomerToQuestionnaire(customer.id, vm.questionnaire.id)
          
          vm.questionnaireQuestionResponses.forEach(questionnaireQuestionResponse => {
            AppService.postQuestionnaireQuestionResponse(questionnaireQuestionResponse, currentUser.contactId)
          })

          AppService.getComponentByName(currentUser.contact.contactType.type).then(res => {
            var component = res.data

            MeService.getProfileTasks(sessionStorage.getItem('profileId'), component.id).then(res => {
              var profileTasks = res.data

              var thisTask = profileTasks.find(profileTask => profileTask.task.action === 'shell.auth.questionnaire')

              MeService.postTaskResponse(thisTask.taskId, currentUser.contactId).then(() => $state.go('shell.auth.me.home'))
            })

            var shell = $scope.$parent.$parent.shell
            shell.postedQuestionnaireQuestionResponses = true
            $timeout(function() {
              shell.hideAlert('postedQuestionnaireQuestionResponses')
            }, 5000)
          })
        })
      })
    })
  }
}