ArticleService.$inject = ['$http']

export default function ArticleService($http) {
  return {
    getArticles: function(profileId) {
      return $http.get('/api-prema2/posts?filter={"where":{"profileId":"' + profileId + '","isPublished":true},"include":[{"relation":"createdBy","scope":{"include":"contact"}},{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"createdAt DESC"}')
    },
    getArticle: function(profileId, slug) {
      return $http.get('/api-prema2/posts/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}')
    }
  }
}