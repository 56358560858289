SubscriptionsController.$inject = ['$rootScope', 'SubscriptionService', '$stateParams', '$state' ,'$timeout', 'AuthService', 'AppService', '$uibModal']

export default function SubscriptionsController($rootScope, SubscriptionService, $stateParams, $state, $timeout, AuthService, AppService, $uibModal) {
  var vm = this

  function calcSubtotal() {
    vm.subtotal = vm.membershipLineItems.map(membershipLineItem => membershipLineItem.totalValue).reduce((a, b) => a + b, 0)
    vm.renews = [...new Set(vm.membershipLineItems.map(membershipLineItem => membershipLineItem.subscription.recurrencePatternId ? membershipLineItem.subscription.recurrencePattern.pattern : undefined))]
    vm.renewsIds = [...new Set(vm.membershipLineItems.map(membershipLineItem => membershipLineItem.subscription.recurrencePatternId ? membershipLineItem.subscription.recurrencePattern.id : undefined))]
    vm.membership.grossValue = vm.subtotal
    vm.membership.netValue = vm.subtotal
    vm.membership.recurrencePattern = {
      pattern: vm.renews,
      id: vm.renewsIds.join()
    }
    sessionStorage.setItem('membership', JSON.stringify(vm.membership))
  }

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      SubscriptionService.getSubscriptions(profileId).then(res => vm.subscriptions = res.data)
    }
  })
  vm.membershipLineItems = sessionStorage.hasOwnProperty('membershipLineItems') ? JSON.parse(sessionStorage.getItem('membershipLineItems')) : new Array()
  vm.isAuthenticated = AuthService.isAuthenticated()
  if (vm.isAuthenticated) AuthService.getCurrentUser().then(res => vm.currentUser = res.data)
  vm.membership = sessionStorage.hasOwnProperty('membership') ? JSON.parse(sessionStorage.getItem('membership')) : {
    grossValue: 0,
    netValue: 0
  }
  vm.payment = new Object()
  AppService.getCountries().then(res => vm.countries = res.data)

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.numberOfPages = function() {
    return parseInt(vm.subscriptions.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
  vm.addToMembership = function(index) {
    var membershipLineItem = {
      subscription: {
        subscriptionNo: vm.subscriptions[index].subscriptionNo ? vm.subscriptions[index].subscriptionNo : undefined,
        subscription: vm.subscriptions[index].subscription,
        unitSalePrice: vm.subscriptions[index].unitSalePrice ? vm.subscriptions[index].unitSalePrice : 0,
        id: vm.subscriptions[index].id,
        recurrencePattern: vm.subscriptions[index].recurrencePatternId ? {
          pattern: vm.subscriptions[index].recurrencePattern.pattern,
          id: vm.subscriptions[index].recurrencePattern.id
        } : undefined
      },
      count: 1,
      totalValue: vm.subscriptions[index].unitSalePrice ? vm.subscriptions[index].unitSalePrice : 0
    }
    if ($rootScope.profile.isVatRegistered) membershipLineItem.vatRate = vm.subscriptions[index].vatRate ? vm.subscriptions[index].vatRate : $rootScope.profile.settings.stores.vatRate

    var membershipLineItemSubscriptionIds = vm.membershipLineItems.map(membershipLineItem => membershipLineItem.subscription.id)
    if (membershipLineItemSubscriptionIds.includes(vm.subscriptions[index].id)) {
      var existingMembershipLineItem = vm.membershipLineItems.find(membershipLineItem => membershipLineItem.subscription.id === vm.subscriptions[index].id)
      existingMembershipLineItem.count = existingMembershipLineItem.count + membershipLineItem.count
      existingMembershipLineItem.totalValue = vm.subscriptions[index].unitSalePrice * existingMembershipLineItem.count
    } else {
      vm.membershipLineItems.push(membershipLineItem)
    }

    sessionStorage.setItem('membershipLineItems', JSON.stringify(vm.membershipLineItems))
    calcSubtotal()
    vm.addedToMembership = true
    $timeout(function() {
      vm.hideAlert('addedToMembership')
    }, 5000)
  }
  vm.showNewMembershipModal = function() {
    function modalInstance() {
      $uibModal.open({
        controller: 'ModalMembershipController',
        controllerAs: 'modalMembership',
        template: require('./membership-modal-new.html'),
        resolve: {
          membership: function() {
            return vm.membership
          },
          membershipLineItems: function() {
            return vm.membershipLineItems
          }
        }
      }).result.then(angular.noop, angular.noop)
    }

    if (AuthService.isAuthenticated()) {
      modalInstance()
    } else {
      $uibModal.open({
        template: require('../auth/login-modal.html')
      }).result.then(() => modalInstance())
    }
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}