import angular from 'angular'

function articleCard() {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.headingClass = attrs.headingClass
    },
    template: require('./_card-article.html')
  }
}

export default angular.module('directives.articleCard', [])
  .directive('articleCard', articleCard)
  .name