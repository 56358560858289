HomeController.$inject = ['$rootScope', 'AppService', 'ArticleService', 'ProductService', 'ServiceService', 'EventService', 'StudyService', 'SubscriptionService', 'LocationService']

export default function HomeController($rootScope, AppService, ArticleService, ProductService, ServiceService, EventService, StudyService, SubscriptionService, LocationService) {
  var vm = this
  
  $rootScope.$watch('profile', function(profile) {
    if (profile !== undefined) {
      AppService.getHomePageHeros(profile.id).then(res => {
        if (res) vm.homePageHeros = res.data
      })
      if (profile.locationPrimary && profile.locationPrimary.operatingHours) {
        var timeOptions = {
          weekday: 'short'
        }

        var d = new Date()
        vm.day = d.toLocaleDateString('en-CY', {weekday: 'long'})
        vm.today = profile.locationPrimary.operatingHours[d.toLocaleDateString('en-CY', {weekday: 'short'}).toLowerCase()]
        vm.telephone = profile.locationPrimary.communications ? profile.locationPrimary.communications.find(communication => communication.type === 'Telephone') : null
      }
      ArticleService.getArticles(profile.id).then(res => vm.articles = res.data)
      ProductService.getProducts(profile.id).then(res => vm.products = res.data)
      ServiceService.getServices(profile.id).then(res => vm.services = res.data)
      EventService.getUpcomingAndRecurringEvents(profile.id).then(res => {
        vm.upcomingAndRecurringEvents = res.data

        vm.upcomingAndRecurringEvents.forEach(event => {
          if (profile.settings.events.reveal) {
            event.revealInHours = profile.settings.events.reveal / 1000 / 60 / 60
            event.eventStartDateUtcMinusReveal = new Date(Date.parse(event.startDateUtc) - profile.settings.events.reveal).toISOString()
          }
        })

        vm.upcomingAndRecurringEvents.map(upcomingOrRecurringEvent => {
          if (upcomingOrRecurringEvent.isRecurring) {
            upcomingOrRecurringEvent.dayOfWeek = new Date(upcomingOrRecurringEvent.startDateUtc).getDay()
            if (upcomingOrRecurringEvent.dayOfWeek == 0) {
              upcomingOrRecurringEvent.dayOfWeek = 7
            }
          }
          
          return upcomingOrRecurringEvent
        })
        var dayOfWeekToday = new Date().getDay()
        var daysOfWeek = [1,2,3,4,5,6,7]

        var arrOfDayOfWeek = vm.upcomingAndRecurringEvents.map(recurringEvent => recurringEvent.dayOfWeek).sort((a, b) => a - b)
        var arrOfNotDayOfWeek = daysOfWeek.filter(item => !arrOfDayOfWeek.includes(item)).concat(arrOfDayOfWeek.filter(item => !daysOfWeek.includes(item)))
        var daysOfWeekSortByDayOfWeekToday = daysOfWeek.slice(dayOfWeekToday - 1).concat(daysOfWeek.slice(0,dayOfWeekToday - 1))
        var arrOfDayOfWeekSortByDayOfWeekToday = daysOfWeekSortByDayOfWeekToday.filter(item => !arrOfNotDayOfWeek.includes(item))

        vm.upcomingAndRecurringEvents.sort((a, b) => arrOfDayOfWeekSortByDayOfWeekToday.indexOf(a.dayOfWeek) - arrOfDayOfWeekSortByDayOfWeekToday.indexOf(b.dayOfWeek))
      })
      StudyService.getStudies(profile.id).then(res => vm.studies = res.data)
      AppService.getReviews(profile.id).then(res => vm.reviews = res.data)
      SubscriptionService.getSubscriptions(profile.id).then(res => vm.subscriptions = res.data)
      LocationService.getLocations(profile.id).then(res => vm.locations = res.data)

      vm.ctas = [
        {isPublished:profile.settings.bookings.isPublished,cta:'Make a booking',action:'showNewBookingModal()'},
        {isPublished:profile.settings.events.isPublished,cta:'Discover what\'s on',action:'shell.events'},
        {isPublished:profile.settings.orders.isPublished,cta:'Browse the shop',action:'shell.products'},
        {isPublished:profile.settings.memberships.isPublished,cta:'Become a member',action:'shell.subscriptions'},
        // {isPublished:profile.settings.contracts.isPublished,cta:'See what we do',action:'shell.services'}
      ].filter(cta => cta.isPublished)
    }
  })
}