CorporateService.$inject = ['$http']

export default function CorporateService($http) {
  return {
    getTeam: function(profileId) {
      return $http.get('/api-prema2/profiles/' + profileId + '/workers?filter={"where":{"isPublished":true},"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}},"order":"order ASC"}')
    },
    getWorkers: function(profileId) {
      return $http.get('/api-prema2/profiles/' + profileId + '/workers?filter={"where":{"isActive":true},"include":["workerRoles",{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}],"order":"order ASC"}')
    },
    getFaqs: function(profileId) {
      return $http.get('/api-prema2/profiles/' + profileId + '/faqs?filter={"order":"order ASC"}')
    },
    getProfilePaymentMethods: function(profileId) {
      return $http.get('/api-prema2/profilePaymentMethods?filter={"where":{"profileId":"' + profileId + '","paymentMethodId":{"neq":"606338bacb9ba02c7a8f3a9e"}},"include":"paymentMethod","order":"order ASC"}')
    }
  }
}