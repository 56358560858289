FaqsController.$inject = ['$rootScope', 'CorporateService']

export default function FaqsController($rootScope, CorporateService) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      CorporateService.getFaqs(profileId).then(res => vm.faqs = res.data)
    }
  })
}