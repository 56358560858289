ProductController.$inject = ['$rootScope', 'ProductService', '$stateParams', 'OrderService', '$timeout', 'angularGridInstance']

export default function ProductController($rootScope, ProductService, $stateParams, OrderService, $timeout, angularGridInstance) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      ProductService.getProduct(profileId, $stateParams.slug).then(res => {
        vm.product = res.data
    
        vm.max = vm.product.stockCount || vm.product.stockCount > 0 ? vm.product.stockCount : 99
    
        if (vm.product.productVariations.length > 0) {
          function htmlDecode(input) {
            var e = document.createElement('div')
            e.innerHTML = input
            return e.childNodes[0].nodeValue
          }
          vm.product.productVariations.forEach(function(productVariation) {
            productVariation.variation = htmlDecode(productVariation.variation)
          })
    
          var productVariationsUnitSalePrices = vm.product.productVariations.map(productVariation => productVariation.unitSalePrice)
          vm.productVariationsMinimumUnitSalePrice = Math.min(...productVariationsUnitSalePrices)
        }
      })
    }
  })
  vm.newBasketItem = {
    count: 1
  }

  vm.showMedia2 = function(media, heros, bodys) {
    heros[heros.length - 1].mediaPlacement.placement = 'Body'
    bodys.splice(bodys.indexOf(media), 1, heros[heros.length - 1])

    media.mediaPlacement.placement = 'Hero'
    heros.splice(heros.length - 1, 1, media)

    $timeout(function() {
      angularGridInstance.grid.refresh()
    }, 2000)
  }
  vm.showMedia = function(media, heros, bodys) {
    vm.product.imgs.bodyImgs.splice(bodys.indexOf(media), 1, { order: bodys.indexOf(media), imgId: heros })

    vm.product.imgs.imgId = media.imgId

    $timeout(function() {
      angularGridInstance.grid.refresh()
    }, 2000)
  }
  vm.setProductVariation = function() {
    var productVariation = vm.product.productVariations.filter(productVariation => productVariation.id === vm.productVariationId)

    var productVariationStockCount = productVariation.map(productVariation => productVariation.stockCount).reduce((a, b) => a + b, 0)
    vm.max = productVariationStockCount || productVariationStockCount > 0 ? productVariationStockCount : 99

    vm.productVariationUnitSalePrice = productVariation.map(productVariation => productVariation.unitSalePrice).reduce((a, b) => a + b, 0)
  }
  vm.addToBasket = function() {
    vm.newBasketItem = {
      count: vm.newBasketItem.count,
      totalValue: vm.product.unitSalePrice * vm.newBasketItem.count,
      productId: vm.product.id
    }
    if ($rootScope.profile.isVatRegistered) vm.newBasketItem.vatRate = vm.product.vatRate ? vm.product.vatRate : $rootScope.profile.settings.stores.vatRate

    var basketItemProductIds = OrderService.basketItems.map(basketItem => basketItem.productId)
    if (basketItemProductIds.includes(vm.product.id)) {
      var basketItem = OrderService.basketItems.find(basketItem => basketItem.productId === vm.product.id)
      basketItem.count = basketItem.count + vm.newBasketItem.count
      basketItem.totalValue = vm.product.unitSalePrice * basketItem.count
    } else {
      OrderService.basketItems.push(vm.newBasketItem)
    }

    sessionStorage.setItem('basketItems', JSON.stringify(OrderService.basketItems))
    vm.addedToBasket = true
    $timeout(function() {
      vm.hideAlert('addedToBasket')
    }, 5000)
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}