loginForm.$inject = ['AuthService', '$state', '$uibModal', '$stateParams', '$timeout']

export default function loginForm(AuthService, $state, $uibModal, $stateParams, $timeout) {
  var directive = {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.typePassword = 'password'
      scope.rememberMe = true
      scope.invalidLogin = false
      
      scope.showPassword = function() {
        scope.typePassword = scope.typePassword === 'password' ? 'text' : 'password'
      }
      scope.doLogin = function() {
        scope.submitted = true
        
        if (!localStorage.getItem('token')) {
          AuthService.login(scope.email, scope.password).then(() => {
            if (scope.$$topModalIndex === 0) scope.$close()
            if ($state.params.previous) {
              $state.go($state.params.previous, {'slug': $state.params.slug}, {reload: true})
            } else if ($state.current.name === 'shell.home' || $state.current.name === 'login') {
              $state.go('shell.auth.me.home', {}, {reload: true})
            } else {
              $state.reload()
            }
          }, () => {
            scope.invalidLogin = true
            scope.submitted = false
            $timeout(function() {
              scope.hideAlert('invalidLogin')
            }, 5000)
          })
        }
      }
      scope.showForgotPasswordModal = function() {
        scope.$dismiss()
        $uibModal.open({
          template: require('./forgot-password-modal.html')
        }).result.then(angular.noop, angular.noop)
      }
      scope.dismissModalAndGoToRegister = function() {
        scope.$dismiss()
        $state.go('register', {'previous': $state.current.name, 'slug': $stateParams.slug})
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-login.html')
  }

  return directive
}