MeController.$inject = ['AuthService', 'MeService', '$uibModal']

export default function MeController(AuthService, MeService, $uibModal) {
  var vm = this

  AuthService.getCurrentUser().then(res => {
    vm.currentUser = res.data

    if (vm.currentUser.contact.contactType.type === 'Account') {
      MeService.getAccount(vm.currentUser.contact.id).then(res => vm.business = res.data)
    } else if (vm.currentUser.contact.contactType.type === 'Supplier') {
      MeService.getSupplier(vm.currentUser.contact.id).then(res => vm.business = res.data)
    }
  })

  vm.showChangePasswordModal = function() {
    $uibModal.open({
      template: require('../auth/password-modal.html')
    }).result.then(angular.noop, angular.noop)
  }
}