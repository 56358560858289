import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './subscription.routes'
import SubscriptionService from './subscription.service'
import SubscriptionCard from './subscription-card.directive'
import MembershipForm from './membership-form.directive'
import SubscriptionsController from './subscriptions.controller'
import ModalMembershipController from './modal-membership.controller'
import './subscription.less'

export default angular.module('app.subscription', [uirouter, SubscriptionCard, MembershipForm])
  .config(routing)
  .service('SubscriptionService', SubscriptionService)
  .controller('SubscriptionsController', SubscriptionsController)
  .controller('ModalMembershipController', ModalMembershipController)
  .name