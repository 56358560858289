ServiceService.$inject = ['$http']

export default function ServiceService($http) {
  return {
    getServices: function(profileId) {
      return $http.get('/api-prema2/services?filter={"where":{"profileId":"' + profileId + '","isPublished":true},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"paymentType"],"order":"order ASC"}')
    },
    getService: function(profileId, slug) {
      return $http.get('/api-prema2/services/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":["studies",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}')
    }
  }
}