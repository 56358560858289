registerForm.$inject = ['AuthService', '$state', '$timeout']

export default function registerForm(AuthService, $state, $timeout) {
  var directive = {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.rememberMe = true
      scope.state = $state.current.name

      scope.doRegister = function() {
        scope.submitted = true

        AuthService.getSysUserByEmail(scope.email).then(() => {
          scope.auth.existingUser = true
          $timeout(function() {
            scope.auth.hideAlert('existingUser')
            $state.go('login')
          }, 5000)
        }, () => {
          AuthService.register(scope.state, scope.firstName, scope.lastName, scope.businessName, scope.email, scope.password).then(() => {
            AuthService.login(scope.email, scope.password).then(() => {
              if ($state.params.previous) {
                $state.go($state.params.previous, {'slug': $state.params.slug})
              } else {
                $state.go('shell.auth.me.home')
              }
            })
          })
        })
      }
      scope.dismissModal = function() {
        scope.$dismiss()
      }
    },
    template: require('./_form-register.html')
  }

  return directive
}