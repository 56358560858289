ModalEventGuestController.$inject = ['event', 'AuthService', 'eventGuests', 'EventService', '$uibModalInstance', '$scope', '$timeout']

export default function ModalEventGuestController(event, AuthService, eventGuests, EventService, $uibModalInstance, $scope, $timeout) {
  var vm = this

  vm.event = event
  if (AuthService.isAuthenticated()) {
    AuthService.getCurrentUser().then(res => {
      vm.currentUser = res.data

      if (eventGuests) vm.eventGuests = eventGuests.filter(eventGuest => eventGuest.createdById === vm.currentUser.id)
    })
  }
  vm.eventGuestsToBeRemoved = new Array()
  vm.confirmDismiss = false

  vm.cancelEventGuests = function() {
    vm.submitted = true
    new Promise((resolve, reject) => {
      var counter = 0
      vm.eventGuestsToBeRemoved.forEach(eventGuest => {
        EventService.postEventResponse(eventGuest, '607e968dd2c3b19773734323').then(() => {
          counter++
          if (counter === vm.eventGuestsToBeRemoved.length) resolve()
        })
      })
    }).then(() => {
      $uibModalInstance.close()
    })
  }
  $scope.$on('modal.closing', function(event, reason, closed) {
    if (!closed && reason !== 'cancel') {
      vm.confirmDismiss = true
      event.preventDefault()
    }
  })
  vm.confirmGuestlist = function() {
    $uibModalInstance.close(vm.email)
  }
  vm.doDismiss = function() {
    vm.hideAlert('confirmDismiss')
    $timeout(function() {
      $uibModalInstance.dismiss('cancel')
    }, 500)
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}