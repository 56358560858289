AuthService.$inject = ['$http', 'AppService']

export default function AuthService($http, AppService) {
  return {
    register: function(state, firstName, lastName, businessName, email, password) {
      if (state === 'shell.newSupplier') {
        return $http.get('/api-prema2/contactTypes').then(res => {
          var contactTypes = res.data

          var contact = {
            firstName: AppService.toTitleCase(firstName),
            lastName: lastName ? AppService.toTitleCase(lastName) : undefined,
            displayName: AppService.toTitleCase(firstName),
            profileId: sessionStorage.getItem('profileId'),
            contactTypeId: contactTypes[4].id
          }

          if (email) contact.communications = new Array()
          
          if (email) contact.communications.push({
            method: 'Email',
            value: email,
            order: contact.communications.length + 1,
            isPrimary: true
          })

          return $http.post('/api-prema2/contacts', contact).then(res => {
            var contact = res.data

            var supplier = {
              businessName: AppService.toTitleCase(businessName),
              slug: AppService.toSlug(businessName),
              createdAt: new Date(),
              updatedAt: new Date(),
              profileId: sessionStorage.getItem('profileId')
            }

            return $http.post('/api-prema2/suppliers', supplier).then(res => {
              var supplier = res.data

              var contactToSupplier = {
                contactId: contact.id,
                supplierId: supplier.id
              }

              return $http.post('/api-prema2/contactToSuppliers', contactToSupplier).then(function() {
                var sysUser = {
                  email: email,
                  password: password,
                  profileId: sessionStorage.getItem('profileId'),
                  contactId: contact.id
                }
  
                return $http.post('/api-prema2/sysUsers', sysUser)
              })
            })
          })
        })
      } else {
        return $http.get('/api-prema2/contactTypes').then(res => {
          var contactTypes = res.data

          var contact = {
            firstName: AppService.toTitleCase(firstName),
            lastName: lastName ? AppService.toTitleCase(lastName) : undefined,
            displayName: AppService.toTitleCase(firstName),
            profileId: sessionStorage.getItem('profileId'),
            contactTypeId: !businessName ? contactTypes[2].id : contactTypes[3].id
          }

          if (email) contact.communications = new Array()
          
          if (email) contact.communications.push({
            method: 'Email',
            value: email,
            order: contact.communications.length + 1,
            isPrimary: true
          })

          return $http.post('/api-prema2/contacts', contact).then(res => {
            var contact = res.data

            var sysUser = {
              email: email,
              password: password,
              profileId: sessionStorage.getItem('profileId'),
              contactId: contact.id
            }

            return $http.post('/api-prema2/sysUsers', sysUser).then(function() {
              if (businessName) {
                var account = {
                  businessName: AppService.toTitleCase(businessName),
                  profileId: sessionStorage.getItem('profileId')
                }

                return $http.post('/api-prema2/accounts', account).then(res => {
                  var account = res.data

                  var contactToAccount = {
                    contactId: contact.id,
                    accountId: account.id
                  }

                  return $http.post('/api-prema2/contactToAccounts', contactToAccount)
                })
              }
            })
          })
        })
      }
    },
    login: function(email, password) {
      var data = {
        email: email,
        password: password
      }
      
      return $http.post('/api-prema2/sysUsers/login', data).then(res => {
        localStorage.setItem('token', res.data.id)
        localStorage.setItem('expires', JSON.stringify(Date.now() + (86400000 * 14)))
        localStorage.setItem('userId', res.data.userId)
      })
    },
    logout: function() {
      return $http.post('/api-prema2/sysUsers/logout?access_token=' + localStorage.getItem('token'), { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        localStorage.clear()
        sessionStorage.removeItem('profileId')
      })
    },
    changePassword: function(password, accessToken) {
      var data = {
        password: password
      }

      return $http.patch('/api-prema2/sysUsers/' + accessToken.userId + '?access_token=' + accessToken.id, data, { headers: { 'Authorization': accessToken.id } })
    },
    resetPasswordRequest: function(email) {
      var data = {
        email: email
      }

      return $http.post('/api-prema2/sysUsers/reset', data)
    },
    getAccessTokenById: function(id) {
      return $http.get('/api-prema2/accessTokens/' + id + '?access_token=' + id, { headers: { 'Authorization': id } })
    },
    isAuthenticated: function() {
      return localStorage.getItem('token') ? true : false
    },
    getCurrentUser: function() {
      return $http.get('/api-prema2/sysUsers/' + localStorage.getItem('userId') + '?filter={"include":[{"relation":"contact","scope":{"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},{"relation":"locations","scope":{"include":"country"}},"contactType"]}},{"relation":"profile","scope":{"include":{"relation":"locations","scope":{"include":"country"}}}}]}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getSysUserByEmail: function(email) {
      return $http.get('/api-prema2/sysUsers/findOne?filter={"where":{"email":"' + email + '"}}')
    }
  }
}