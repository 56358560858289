MeProfileController.$inject = ['AppService', 'AuthService', 'MeService', '$rootScope', '$timeout', '$state', '$scope']

export default function MeProfileController(AppService, AuthService, MeService, $rootScope, $timeout, $state, $scope) {
  var vm = this

  AppService.getMediaPlacements().then(res => vm.mediaPlacements = res.data)
  AppService.getCountries().then(res => vm.countries = res.data)
  AuthService.getCurrentUser().then(res => {
    vm.currentUser = res.data

    if (vm.currentUser.contact.contactType.type === 'Customer') {
      if (vm.currentUser.contact.medias) vm.medias = vm.currentUser.contact.medias
      if (vm.currentUser.contact.locations) {
        vm.location = vm.currentUser.contact.locations.find(location => location.isPrimary)
        vm.locationShipping = vm.currentUser.contact.locations.find(location => !location.isPrimary)
      }
      vm.isPrimaryLocationForShipping = vm.locationShipping ? false : true
      if (vm.currentUser.contact.communications) vm.communications = vm.currentUser.contact.communications
      if (vm.currentUser.contact.socials) vm.socials = vm.currentUser.contact.socials
    } else if (vm.currentUser.contact.contactType.type === 'Owner' || vm.currentUser.contact.contactType.type === 'Employee') {
      if (vm.currentUser.contact.medias) vm.medias = vm.currentUser.contact.medias
      if (vm.currentUser.profile.locations) {
        vm.location = vm.currentUser.profile.locations.find(location => location.isPrimary)
        vm.locationShipping = vm.currentUser.profile.locations.find(location => !location.isPrimary)
      }
      vm.isPrimaryLocationForShipping = vm.locationShipping ? false : true
      if (vm.currentUser.contact.communications) vm.communications = vm.currentUser.contact.communications
      if (vm.currentUser.contact.socials) vm.socials = vm.currentUser.contact.socials
    } else if (vm.currentUser.contact.contactType.type === 'Account') {
      MeService.getAccount(vm.currentUser.contact.id).then(res => {
        vm.business = res.data

        if (vm.currentUser.contact.medias) vm.medias = vm.currentUser.contact.medias
        if (vm.business.locations) {
          vm.location = vm.business.locations.find(location => location.isPrimary)
          vm.locationShipping = vm.business.locations.find(location => !location.isPrimary)
        }
        vm.isPrimaryLocationForShipping = vm.locationShipping ? false : true
        if (vm.currentUser.contact.communications) vm.communications = vm.currentUser.contact.communications
        if (vm.currentUser.contact.socials) vm.socials = vm.currentUser.contact.socials
      })
    } else if (vm.currentUser.contact.contactType.type === 'Supplier') {
      MeService.getSupplier(vm.currentUser.contact.id).then(res => {
        vm.business = res.data

        if (vm.business.medias) vm.medias = vm.business.medias
        if (vm.business.locations) {
          vm.location = vm.business.locations.find(location => location.isPrimary)
          vm.locationShipping = vm.business.locations.find(location => !location.isPrimary)
        }
        vm.isPrimaryLocationForShipping = vm.locationShipping ? false : true
        if (vm.business.communications) vm.communications = vm.business.communications
        if (vm.business.description) vm.description = vm.business.description
        if (vm.business.socials) vm.socials = vm.business.socials
      })
    }
  })

  vm.uploadMedias = function(files, placement, invalidFiles) {
    files.forEach((file, index) => {
      vm.uploadedFile = true
      $rootScope.$on('uploadProgress', (event, data) => vm.uploadProgress = data)

      AppService.uploadMedia(file).then(res => {
        var file = res.data

        vm.uploadProgress = 100.0
        $timeout(function() {
          vm.hideAlert('uploadedFile')
        }, 5000)

        if (!vm.medias) vm.medias = new Array()

        var media, originalMedia
        new Promise((resolve, reject) => {
          if (file.type.includes('video')) {
            AppService.getMediaById(file.attrs.mediaId).then(res => {
              originalMedia = file
              media = res.data
              resolve()
            })
          } else {
            media = file
            resolve()
          }
        }).then(() => {
          var existingContactToMediasSamePlacement = vm.medias.filter(media => media.mediaPlacement.placement.toLowerCase() == placement)
          var mediaPlacement = vm.mediaPlacements.find(mediaPlacement => mediaPlacement.placement.toLowerCase() == placement)
          var newContactToMediaSamePlacement = {
            order: existingContactToMediasSamePlacement.length + 1,
            mediaId: file.id,
            mediaPlacementId: mediaPlacement.id,
            media: media,
            mediaPlacement: mediaPlacement
          }
          if (originalMedia) newContactToMediaSamePlacement.originalMedia = originalMedia
          vm.medias.push(newContactToMediaSamePlacement)
          if (existingContactToMediasSamePlacement.length === 1 && existingContactToMediasSamePlacement[0].mediaPlacement.isReplace) {
            var index = vm.medias.findIndex(media => media === existingContactToMediasSamePlacement[0], 1)
            vm.medias.splice(index, 1)
            newContactToMediaSamePlacement.order = newContactToMediaSamePlacement.order - 1
          }
        })
      })
    })
    invalidFiles.forEach((file, index) => {
      vm.invalidFile = true
      vm.file = file
      $timeout(function() {
        vm.hideAlert('invalidFile')
      }, 5000)
    })
  }
  vm.removeMedia = function(media) {
    var index = vm.medias.findIndex(media2 => media2.id === media.id)
    vm.medias.splice(index, 1)
  }
  vm.addCommunication = function() {
    if (!vm.communications) vm.communications = new Array()
    vm.communications.push({
      order: vm.communications.length + 1,
      isPrimary: vm.communications.length === 0 ? true : undefined
    })
  }
  vm.deleteCommunication = function(index) {
    vm.communications.splice(index, 1)
  }
  vm.addSocial = function() {
    if (!vm.socials) vm.socials = new Array()
    vm.socials.push({
      order: vm.socials.length + 1
    })
  }
  vm.deleteSocial = function(index) {
    vm.socials.splice(index, 1)
  }
  vm.updateProfile = function() {
    vm.submitted = true
    
    if (vm.currentUser.contact.contactType.type === 'Customer') {
      if (vm.location) MeService.putLocation(vm.location, true, vm.currentUser.contact.contactType.type, vm.currentUser.contact.id)
      if (vm.locationShipping) MeService.putLocation(vm.locationShipping, undefined, vm.currentUser.contact.contactType.type, vm.currentUser.contact.id)
    } else if (vm.currentUser.contact.contactType.type === 'Owner' || vm.currentUser.contact.contactType.type === 'Employee') {
      if (vm.locationShipping) MeService.putLocation(vm.locationShipping, undefined, vm.currentUser.contact.contactType.type, vm.currentUser.contact.id)
    } else if (vm.currentUser.contact.contactType.type === 'Account') {
      if (vm.location) MeService.putLocation(vm.location, true, vm.currentUser.contact.contactType.type, vm.business.id)
      if (vm.locationShipping) MeService.putLocation(vm.locationShipping, undefined, vm.currentUser.contact.contactType.type, vm.business.id)
    } else if (vm.currentUser.contact.contactType.type === 'Supplier') {
      if (vm.location) MeService.putLocation(vm.location, true, vm.currentUser.contact.contactType.type, vm.business.id)
      if (vm.locationShipping) MeService.putLocation(vm.locationShipping, undefined, vm.currentUser.contact.contactType.type, vm.business.id)
    }

    if (vm.currentUser.contact.contactType.type === 'Supplier') {
      if (vm.medias) vm.business.medias = vm.medias
      if (vm.communications) vm.business.communications = vm.communications
      if (vm.description) vm.business.description = vm.description
      if (vm.socials) vm.business.socials = vm.socials
      MeService.putSupplier(vm.business)
    } else {
      if (vm.medias) vm.currentUser.contact.medias = vm.medias
      if (vm.communications) vm.currentUser.contact.communications = vm.communications
      MeService.putContact(vm.currentUser.contact)
    }

    AppService.getComponentByName(vm.currentUser.contact.contactType.type).then(res => {
      var component = res.data
    
      MeService.getProfileTasks(sessionStorage.getItem('profileId'), component.id).then(res => {
        var profileTasks = res.data

        var thisTask = profileTasks.find(profileTask => profileTask.task.action === 'shell.auth.me.profile')

        MeService.postTaskResponse(thisTask.taskId, vm.currentUser.contactId).then(() => $state.go('shell.auth.me.home'))

        var shell = $scope.$parent.$parent.shell
        shell.updatedProfile = true
        $timeout(function() {
          shell.hideAlert('updatedProfile')
        }, 5000)
      })
    })
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}