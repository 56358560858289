routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.basket', {
      url: '/basket',
      template: require('./basket.html'),
      controller: 'BasketController',
      controllerAs: 'basket',
      resolve: {
        $title: function() {
          return 'Basket'
        }
      }
    })
    .state('shell.checkout', {
      url: '/checkout',
      template: require('./checkout.html'),
      controller: 'CheckoutController',
      controllerAs: 'checkout',
      resolve: {
        $title: function() {
          return 'Checkout'
        }
      }
    })
    .state('shell.deliveryInformation', {
      url: '/delivery-information',
      template: require('./delivery-information.html'),
      controller: 'DeliveryInformationController',
      controllerAs: 'deliveryInformation',
      resolve: {
        $title: function() {
          return 'Delivery information'
        }
      }
    })
    .state('shell.auth.order', {
      url: '/orders/:referenceNo',
      template: require('./order.html'),
      controller: 'OrderController',
      controllerAs: 'order',
      resolve: {
        order: ['OrderService', '$stateParams', function(OrderService, $stateParams) {
          return OrderService.getOrder($stateParams.referenceNo).then(res => res.data)
        }],
        $title: ['order', function(order) {
          return 'Ref. &#35;' + order.referenceNo
        }]
      }
    })
}