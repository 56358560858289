import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './event.routes'
import EventService from './event.service'
import EventCard from './event-card.directive'
import GuestForm from './guest-form.directive'
import CalendarDirective from './calendar.directive'
import BookingSummaryCard from './booking-summary-card.directive'
import EventsController from './events.controller'
import EventController from './event.controller'
import BookingController from './booking.controller'
import ModalEventGuestController from './modal-event-guest.controller'
import './event.less'

export default angular.module('app.event', [uirouter, EventCard, GuestForm, BookingSummaryCard])
  .config(routing)
  .service('EventService', EventService)
  .directive('calendar', CalendarDirective)
  .controller('EventsController', EventsController)
  .controller('EventController', EventController)
  .controller('BookingController', BookingController)
  .controller('ModalEventGuestController', ModalEventGuestController)
  .name