import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './study.routes'
import StudyService from './study.service'
import StudyCard from './study-card.directive'
import StudiesController from './studies.controller'
import StudyController from './study.controller'
import './study.less'

export default angular.module('app.study', [uirouter, StudyCard])
  .config(routing)
  .service('StudyService', StudyService)
  .controller('StudiesController', StudiesController)
  .controller('StudyController', StudyController)
  .name