import angular from 'angular'
import { loadScript } from '@paypal/paypal-js'

membershipForm.$inject = ['AuthService', 'AppService', 'CorporateService', 'SubscriptionService', '$window', '$state', '$timeout']

function membershipForm(AuthService, AppService, CorporateService, SubscriptionService, $window, $state, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.membershipStep = AuthService.isAuthenticated() ? 2 : 1
      scope.isAuthenticated = AuthService.isAuthenticated()
      if (scope.isAuthenticated) AuthService.getCurrentUser().then(res => scope.currentUser = res.data)
      scope.contact = new Object(); scope.payment = new Object()
      scope.membership = scope.$resolve.membership
      scope.membership.membershipLineItems = scope.$resolve.membershipLineItems
      AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => scope.profilePaymentMethods = res.data)
      CorporateService.getWorkers(sessionStorage.getItem('profileId')).then(res => {
        var workers = res.data

        scope.referrers = workers.filter(worker => worker.workerRoles.some(workerRole => workerRole.role === 'Referrer')).sort((a, b) => a.contact.firstName.localeCompare(b.contact.firstName))
      })
      scope.dateOptions = {
        startingDay: 1
      }
      
      scope.nextMembershipStep = function() {
        scope.membershipStep++
      }
      scope.toggleStartDate = function() {
        scope.startDate = !scope.startDate
      }
      scope.setProfilePaymentMethod = function(paymentMethodId) {
        scope.profilePaymentMethod = scope.profilePaymentMethods.find(profilePaymentMethod => profilePaymentMethod.paymentMethod.id === paymentMethodId)
      }
      scope.createMembership = function() {
        scope.submitted = true

        SubscriptionService.postMembership(scope.membership, scope.currentUser.contact).then(res => {
          var membership = res

          scope.payment.netValue = membership.netValue

          AppService.postPayment('membership', membership, scope.payment).then(res => {
            var payment = res

            var shell = scope.$$topModalIndex === 0 && scope.$$prevSibling ? scope.$$prevSibling.shell : scope.$$topModalIndex === 0 && scope.$$nextSibling ? scope.$$nextSibling.$$nextSibling.shell : scope.$parent.shell
            sessionStorage.removeItem('membership')
            if (scope.$$topModalIndex === 0) scope.$close()
            if (scope.profilePaymentMethod.paymentMethod.method === 'Visa, American Express or MasterCard') {
              var hexPrimary = scope.$parent.profile.settings.hexPrimary.substring(1)
              $window.location.href = '//www.vivapayments.com/web2?ref=' + payment.orderCode + '&color=' + hexPrimary
            } else {
              shell.postedMembership = true
              $state.go('shell.auth.me.home')
              $timeout(function() {
                shell.hideAlert('postedMembership')
              }, 5000)
            }
          })
        })
      }
    },
    template: require('./_form-membership.html')
  }
}

export default angular.module('directives.membershipForm', [])
  .directive('membershipForm', membershipForm)
  .name