CourseService.$inject = ['$http']

export default function CourseService($http) {
  return {
    getCourses: function(profileId) {
      return $http.get('/api-prema2/courses?filter={"where":{"profileId":"' + profileId + '","isPublished":true},"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}')
    },
    getCourse: function(profileId, slug) {
      return $http.get('/api-prema2/courses/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":["durationUnit",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},{"relation":"lessons","scope":{"order":"order ASC"}}]}')
    },
    getLesson: function(profileId, slug) {
      return $http.get('/api-prema2/lessons/findOne?filter[where][profileId]=' + profileId + '&filter[where][slug]=' + slug + '&filter[include]=durationUnit&filter[include]=course')
    }
  }
}