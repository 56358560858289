StudiesController.$inject = ['$rootScope', 'StudyService', '$stateParams', '$timeout', '$state']

export default function StudiesController($rootScope, StudyService, $stateParams, $timeout, $state) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      StudyService.getStudies(profileId).then(res => vm.studies = res.data)
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.setSortBy = function(property) {
    var copy = vm.studies
    vm.studies = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    copy.sort(compare)
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.studies = copy
    }, 100)
  }
  vm.numberOfPages = function() {
    return parseInt(vm.studies.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
}